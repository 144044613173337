import React from "react";

const AboutUs = () => {
  return (
    <div className="w-full h-[90vh] flex items-center justify-center flex-col">
      <span className="text-primary text-3xl" >Coming Soon</span>
    </div>
    // <div className="container mx-auto p-6">
    //   <section className="mb-6">
    //     <h1 className="text-3xl font-bold mb-4">About</h1>
    //     <p className="mb-4">
    //       Eyecentric Pvt. Ltd., a visionary startup, aims to transform the
    //       traditional eyewear retail business model by establishing a robust
    //       shared economy ecosystem. We are focused on, not just selling eyewear
    //       but revolutionizing the entire retail experience.
    //     </p>
    //     <h2 className="text-2xl font-semibold mb-4">
    //       The Genesis of Netré: Bridging Gaps, Creating Opportunities
    //     </h2>
    //     <p className="mb-4">
    //       Netré is one of the valuable brands under the umbrella of Eyecentric.
    //       It was born out of a deep understanding of the challenges faced by
    //       local eyewear retailers and opticians. Many of these businesses
    //       operate in a fragmented and unorganized manner, struggling to compete
    //       in an increasingly digital and competitive market. Recognizing this
    //       gap, Netré is on a mission to create a unified platform that connects
    //       these local businesses, fostering collaboration, efficiency, and
    //       growth.
    //     </p>
    //     <p className="mb-4">
    //       <strong>Note:</strong> Eyecentric Pvt. Ltd. retains all rights to the
    //       terms and conditions outlined herein. By accessing this page, users
    //       agree to abide by the terms and conditions herein referred to as
    //       Netré's t&C, subject to the governance of Eyecentric.
    //     </p>
    //   </section>

    //   <section className="mb-6">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       Communication Consent Policy
    //     </h2>
    //     <p className="mb-4">
    //       By using the Netré's Website, you authorize us to contact you via
    //       email, phone call, or SMS to provide product knowledge, offer
    //       promotional offers, and deliver our services. This may involve the
    //       collection of personally identifiable information. Even if you have
    //       registered under the National Do Not Call (NDNC) or Do Not Disturb
    //       (DND) service, you authorize us to contact you for these purposes
    //       until 45 days after your registration with us.
    //     </p>
    //   </section>

    //   <section className="mb-6">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       Agreement to Terms of Use
    //     </h2>
    //     <p className="mb-4">
    //       By accessing the Netré's Website, you agree to abide by the terms and
    //       conditions outlined below. Netré reserves the right to modify these
    //       terms at any time, with all changes being posted on this website. If
    //       you do not agree to these terms, please refrain from using this
    //       website.
    //     </p>
    //     <p className="mb-4">
    //       Your use of this website constitutes acceptance of these terms,
    //       whether you are a registered member or a visitor. We recommend reading
    //       these terms carefully and saving or printing a copy for your records.
    //     </p>
    //     <p className="mb-4">
    //       Netré services are intended for individuals/entities legally capable
    //       of entering into a contractual agreement. If you are not of legal
    //       contracting age or are otherwise restricted, you may not use our
    //       services unless under parental supervision.
    //     </p>
    //     <p className="mb-4">
    //       By utilizing our services, you represent that you are of legal
    //       contracting age or under parental supervision. Netré reserves the
    //       right to hold you and your parents or legal guardians bound by this
    //       agreement.
    //     </p>
    //     <p className="mb-4">
    //       If you are representing others, you confirm that you have the
    //       authority to do so and bind them to this agreement.
    //     </p>
    //     <p className="mb-4">
    //       Our services facilitate the purchase of original merchandise including
    //       eyewear and other optical-related products like frames, lenses etc.
    //       This website does not provide certifications, guarantees, or
    //       warranties, nor does it offer professional advice.
    //     </p>
    //     <p className="mb-4">
    //       Unauthorized use of our systems or website, including unauthorized
    //       access or misuse of passwords, is strictly prohibited. We may disclose
    //       information provided through this website to affiliated partners or
    //       third parties (with your consent) or as required by law.
    //     </p>
    //     <p className="mb-4">
    //       By using this website, you consent to the transmission, transfer, or
    //       processing of information to any country deemed necessary by Netré.
    //     </p>
    //     <p className="mb-4">
    //       Usage of this website may be monitored, tracked, and recorded, and you
    //       consent to such activities. It is your responsibility to review the
    //       current version of these terms during each visit. Continued use
    //       implies acceptance of any revised terms.
    //     </p>
    //   </section>

    //   <section className="mb-6">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       General Terms and Conditions
    //     </h2>
    //     <ol className="list-decimal list-inside mb-4">
    //       <li>
    //         Returns and Exchanges: Returns and exchanges of products will be
    //         accepted within 15 days from the date of delivery. (unless otherwise
    //         mentioned in a particular offer. Refer to its T&C)
    //       </li>
    //       <li>
    //         Acceptance of Terms: By opting for this offer, the customer confirms
    //         that they have read and understood these detailed Terms and
    //         Conditions and agree to be bound by them.
    //       </li>
    //       <li>
    //         Independent Inquiry: Customers are encouraged to conduct independent
    //         inquiry, investigation, and analysis before availing of this offer.
    //         Netré, its employees, directors, representatives, or affiliates
    //         shall not be liable for any losses incurred by customers in
    //         connection with the sale.
    //       </li>
    //       <li>
    //         Amendment of Terms: Netré reserves the right to amend or modify
    //         these terms, including pricing, without prior intimation.
    //       </li>
    //       <li>
    //         Governing Law and Jurisdiction: These Terms and Conditions are
    //         governed by the laws of India. Any disputes arising out of or in
    //         connection with these terms shall be settled exclusively by the
    //         courts of Ahmedabad, Gujarat.
    //       </li>
    //       <li>
    //         Product Representation: Product images shown under this offer are
    //         for illustrative purposes only and may not accurately represent the
    //         actual product. The Company reserves the right to change product
    //         images and specifications at any time without prior notice.
    //       </li>
    //       <li>
    //         Additional Terms: The terms of use on the Netré's Website shall also
    //         apply in addition to these terms and conditions.
    //       </li>
    //       <li>
    //         Contact Information: For further information or queries, please
    //         contact www.netre.in
    //       </li>
    //     </ol>
    //   </section>
    //   <section className="mb-6">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       Terms & Conditions for Netré Care
    //     </h2>
    //     <ol className="list-decimal list-inside mb-4">
    //       <li>
    //         <strong>Coverage Period:</strong> Netré Care offers coverage for a
    //         specified period from the date of purchase. The coverage period will
    //         be mentioned in the terms of the Netré Care plan chosen by the
    //         customer.
    //       </li>
    //       <li>
    //         <strong>Scope of Coverage:</strong> Netré Care provides additional
    //         benefits such as extended warranty, repair services, and product
    //         support beyond the standard manufacturer warranty. The scope of
    //         coverage may vary depending on the specific Netré Care plan selected
    //         by the customer.
    //       </li>
    //       <li>
    //         <strong>Eligibility:</strong> Netré Care is available for purchase
    //         at the time of buying eligible products from Netré. Customers may
    //         choose to opt-in for Netré Care during the checkout process.
    //       </li>
    //       <li>
    //         <strong>Registration:</strong> Customers opting for Netré Care must
    //         register their plan using the provided registration process.
    //         Registration ensures that the customer's coverage is activated and
    //         allows for seamless access to benefits and services.
    //       </li>
    //       <li>
    //         <strong>Coverage Limitations:</strong> Netré Care coverage is
    //         subject to certain limitations and exclusions, which will be
    //         outlined in the terms and conditions of the plan. Customers are
    //         advised to review these limitations carefully to understand what is
    //         covered and what is not covered under their Netré Care plan.
    //       </li>
    //       <li>
    //         <strong>Claim Process:</strong> In the event of a covered issue with
    //         the product, customers must follow the designated claim process
    //         outlined in the Netré Care terms and conditions. This process may
    //         include contacting customer support, providing proof of purchase,
    //         and adhering to any troubleshooting steps recommended by Netré.
    //       </li>
    //       <li>
    //         <strong>Service Fees:</strong> Some Netré Care plans may require
    //         payment of service fees or deductibles for certain covered services
    //         or repairs. The amount of these fees, if applicable, will be
    //         specified in the terms and conditions of the plan.
    //       </li>
    //       <li>
    //         <strong>Transferability:</strong> Netré Care plans may or may not be
    //         transferable. Customers should refer to the terms and conditions of
    //         their specific plan to determine if transferability is allowed and
    //         any associated requirements or limitations.
    //       </li>
    //       <li>
    //         <strong>Cancellation and Refunds:</strong> Customers may have the
    //         option to cancel their Netré Care plan within a specified period
    //         from the date of purchase, subject to the terms and conditions of
    //         the plan. Any refunds or credits for cancelled plans will be issued
    //         according to the policies outlined in the terms and conditions.
    //       </li>
    //       <li>
    //         <strong>Modification of Terms:</strong> Netré reserves the right to
    //         modify the terms and conditions of Netré Care plans, including
    //         coverage, pricing, and eligibility criteria, at its sole discretion.
    //         Any such modifications will be communicated to customers in advance.
    //       </li>
    //       <li>
    //         <strong>Governing Law:</strong> The terms and conditions of Netré
    //         Care plans are governed by the laws of the jurisdiction in which
    //         Netré operates. Any disputes arising from or related to Netré Care
    //         plans shall be subject to the exclusive jurisdiction of the courts
    //         in that jurisdiction.
    //       </li>
    //       <li>
    //         <strong>Contact Information:</strong> For questions or assistance
    //         regarding Netré Care plans, customers can contact Netré's customer
    //         support team at the provided contact information.
    //       </li>
    //     </ol>
    //     <section className="mb-6">
    //       <h2 className="text-2xl font-semibold mb-4">
    //         Service and Product Description
    //       </h2>
    //       <p>
    //         At Netré, we provide an exclusive opportunity to purchase premium
    //         products sourced from high-end manufacturers and retailers. Upon
    //         placing an order, we will promptly ship the selected product to you,
    //         and you are obligated to complete payment for the services rendered.
    //         All products and information displayed on our website constitute an
    //         "invitation to offer." Your order for purchase constitutes your
    //         "offer," subject to the terms and conditions detailed in this
    //         agreement.
    //       </p>
    //       <p>
    //         We make every effort to accurately display the colours of our
    //         products on the website. However, please note that the colours
    //         visible to you may vary depending on your monitor settings.
    //         Therefore, we cannot guarantee the exact representation of colours
    //         on the website.
    //       </p>
    //       <p>
    //         Netré does not warrant or guarantee that the product descriptions
    //         are completely accurate, reliable, current, or error-free. If a
    //         product received is not as described, your sole remedy is to return
    //         it in an unused, unsoiled, and resaleable condition.
    //       </p>
    //       <p>Our current offerings include:</p>
    //       <ol>
    //         <li>
    //           Eyewear: Explore our wide range of eyewear available through our
    //           website at www.netre.in.
    //         </li>
    //       </ol>
    //       <p>
    //         The ranking of products on our platform is determined by a
    //         combination of launch date and popularity. Recently launched
    //         products are given higher visibility, as are products that are
    //         frequently purchased by customers. The final ranking is based on the
    //         combination of these two factors.
    //       </p>
    //     </section>
    //     <section className="mb-6">
    //       <h2 className="text-2xl font-semibold mb-4">
    //         Your Responsibilities and Covenants Towards the Products
    //       </h2>
    //       <p>
    //         In addition to your other commitments outlined in this Agreement, by
    //         ordering products on the Netré's Website, you acknowledge and agree
    //         to the following:
    //       </p>
    //       <ol>
    //         <li>
    //           <strong>Valid Prescription:</strong> You possess a valid and
    //           written prescription from a licensed doctor for prescription
    //           glasses or lenses, and the information provided to Netré is
    //           accurate.
    //         </li>
    //         <li>
    //           <strong>Verification of Prescription:</strong> You consent to
    //           Netré contacting your doctor to verify the authenticity of the
    //           prescription if required.
    //         </li>
    //         <li>
    //           <strong>Responsibility for Information Accuracy:</strong> In the
    //           event of non-delivery or incorrect delivery of products by Netré
    //           due to errors in the information provided by you, any additional
    //           re-delivery costs will be billed to you in addition to the initial
    //           cost.
    //         </li>
    //         <li>
    //           <strong>Authenticity of Information:</strong> All information
    //           provided by you, including contact details, name, address, and
    //           bank or credit card details, are authentic, and there is no
    //           misrepresentation or fraudulent activity on your part.
    //         </li>
    //         <li>
    //           <strong>Product Description Review:</strong> Before placing an
    //           order, you will carefully review the product description. By
    //           placing an order for a product, you agree to be bound by the
    //           conditions of sale included in the item's description.
    //         </li>
    //       </ol>
    //     </section>
    //     <section className="mb-6">
    //       <h2 className="text-2xl font-semibold mb-4">
    //         Information Required from You
    //       </h2>
    //       <p>
    //         Upon your agreement to become a member and access services from our
    //         website, Netré may request certain basic information for
    //         registration purposes. Subsequently, we may request additional
    //         information to enhance and personalize the services provided to you.
    //         However, we assure you that we will only collect the necessary
    //         information and maintain strict confidentiality.
    //       </p>
    //     </section>
    //     <section className="mb-6">
    //       <h2 className="text-2xl font-semibold mb-4">
    //         Ordering and Supply of Products
    //       </h2>
    //       <p>
    //         To purchase our products, you can submit your order via our website,
    //         by visiting your nearest Netré affiliated store, by email or by
    //         contacting our customer service team. (refer to the website for the
    //         contact information.)
    //       </p>
    //       <p>
    //         Once you submit an order, it indicates your express intention to
    //         purchase the product, and cancellation may only be possible as
    //         provided hereunder.
    //       </p>
    //       <p>
    //         Upon receiving your order and verifying the provided information, we
    //         will accept the order for processing. All orders will be processed
    //         upon receipt of complete payment for the product.
    //       </p>
    //       <p>
    //         For prescription glasses, we reserve the right to verify the
    //         prescription with your prescribing doctor or an independent
    //         qualified optician before accepting the order.
    //       </p>
    //       <p>You will receive an order confirmation via email.</p>
    //       <p>
    //         We strive to ensure the successful processing of your order, subject
    //         to product availability. If a product is sold out or unavailable, we
    //         will notify you accordingly and offer either a refund or an
    //         alternative product of equal value for purchase.
    //       </p>
    //     </section>
    //   </section>
    //   {/* Pricing and Payment */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Pricing and Payment</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li>
    //         (a) Our product prices, conveniently listed on the Netré's Website,
    //         form an integral part of these terms. These prices are denoted in
    //         Indian rupees and are subject to change, reflecting our commitment
    //         to offering competitive pricing.
    //       </li>
    //       <li>
    //         (b) All listed prices include applicable taxes mandated by tax
    //         authorities, ensuring transparency and clarity in your transactions
    //         with us.
    //       </li>
    //       <li>
    //         (c) At Netré, we strive to provide accurate pricing information on
    //         our website. While we make every effort to maintain consistency,
    //         variations may occur compared to in-store offers, reflecting our
    //         dynamic marketplace.
    //       </li>
    //       <li>
    //         (d) In the rare instance of a pricing discrepancy, we pledge to
    //         promptly notify you before processing payment, offering you the
    //         option to affirm the corrected price or reconsider your order.
    //       </li>
    //       <li>
    //         (e) Embracing modern payment solutions, Netré offers various payment
    //         methods to suit your convenience:
    //         <ul className="list-disc list-inside pl-5">
    //           <li>
    //             i) Seamlessly transact online using a Credit Card, Debit Card,
    //             Net Banking, or UPI.
    //           </li>
    //           <li>
    //             ii) Enjoy the flexibility of Cash on Delivery, available in
    //             select regions and select stores and adhere to our streamlined
    //             return and refund policy.
    //           </li>
    //           <li>
    //             iii) Facilitate transactions effortlessly through Cash Deposits
    //             into our designated bank account.
    //           </li>
    //         </ul>
    //       </li>
    //       <li>
    //         (f) Your confidence in us is paramount. By providing your card
    //         details, you affirm their accuracy and legitimacy. If utilizing a
    //         third-party card, you assure us of your explicit authorization for
    //         its use.
    //       </li>
    //       <li>
    //         (g) Netré prioritizes security, yet acknowledges the possibility of
    //         credit card fraud. While we endeavour to safeguard your
    //         transactions, users bear the responsibility for any unauthorized
    //         card activity, underscoring the importance of vigilance and
    //         diligence.
    //       </li>
    //       <li>
    //         (h) In our commitment to integrity, Netré reserves the right to
    //         recover costs incurred due to fraudulent activities. We remain
    //         steadfast in upholding the law and will pursue legal recourse
    //         against violators of our terms.
    //       </li>
    //       <li>
    //         (i) Transparency is foundational to our ethos. Rest assured, Netré
    //         refrains from imposing additional fees for payments. Should payment
    //         gateway charges arise, we will communicate these to you during the
    //         transaction process.
    //       </li>
    //       <li>
    //         (j) Customers are required to make a complete payment to Netré in
    //         order to complete the purchase. The products will not be taken into
    //         production unless complete payment has been processed.
    //       </li>
    //     </ul>
    //   </section>

    //   {/* Delivery and Cancellation */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       Delivery and Cancellation
    //     </h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li>
    //         (a) Netré acknowledges the inherent challenges in ensuring exactness
    //         in the finish and appearance of the final products as ordered, based
    //         solely on the website display. While we strive for precision, the
    //         quality of products, services, information, or other materials
    //         acquired through our website may not always meet your expectations.
    //         Any alterations to your order will be promptly communicated to you,
    //         offering the choice of a full refund or a voucher for an alternative
    //         product of equal value.
    //       </li>
    //       <li>
    //         (b) Upon shipment to the shipping carrier, title and risk of loss
    //         for all products ordered pass on to you.
    //       </li>
    //       <li>
    //         (c) Delivery will be made on a 'best effort' basis following the
    //         acceptance of your order. Subject to circumstances beyond our
    //         control, products will be shipped within 7 days of order acceptance,
    //         times are subject to vary upon certain circumstances.
    //       </li>
    //       <li>
    //         (d) We will promptly communicate dispatch details and ship the
    //         products to the mailing address provided during order placement. If
    //         you do not receive the products within a reasonable timeframe,
    //         please notify us immediately.
    //       </li>
    //       <li>
    //         (e) Upon receipt of the products, you are obligated to promptly
    //         inspect them for accuracy.
    //       </li>
    //       <li>
    //         (f) Netré reserves the right to cancel any order deemed
    //         unacceptable. In such cases, we will refund your payment. Reasons
    //         for cancellation may include product unavailability, inaccuracies in
    //         product or pricing information, or instances of fraud or improper
    //         payment usage. Any cancellations will be promptly communicated to
    //         you.
    //       </li>
    //       <li>
    //         (g) Should you wish to cancel an order, it must be done before the
    //         orders are accepted. Once accepted, cancellation requests will not
    //         be entertained. Acceptance of order will be communicated within a
    //         couple of hours of order placing, via email/SMS/call. If
    //         cancellation is still required post-acceptance of the order, call
    //         customer service to seek the best possible solution.
    //       </li>
    //     </ul>
    //   </section>

    //   {/* External Website Links */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">External Website Links</h2>
    //     <p className="mb-4">
    //       When using our services, you may provide personal information to
    //       access data from third-party websites, such as financial institutions.
    //       Netré does not assume responsibility for the accuracy or maintenance
    //       of such information. Our website may contain links to other sites, but
    //       we do not control or endorse their content. Netré is not liable for
    //       any damage or loss resulting from your use of such sites.
    //     </p>
    //   </section>

    //   {/* User Registration and Confidentiality */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">
    //       User Registration and Confidentiality
    //     </h2>
    //     <p className="mb-4">
    //       You acknowledge and accept responsibility for maintaining the
    //       confidentiality of all information provided to Netré during
    //       registration, including your login ID, email address, and passwords.
    //       You are fully liable for all activities conducted under your email
    //       address, password, or account, and must ensure that you log out of
    //       your account at the end of each session. Netré will correspond with
    //       you via the email address you provide, and it is your responsibility
    //       to update this information if it changes. We employ stringent security
    //       measures to safeguard your information from misuse by third parties.
    //       If you become aware of any unauthorized use or misuse of your
    //       information, please promptly notify us. Netré cannot be held liable
    //       for any loss or damage resulting from your failure to comply with
    //       these provisions.
    //     </p>
    //   </section>

    //   {/* Authorised Use */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Authorised Use</h2>
    //     <p className="mb-4">
    //       Your access to and use of the services provided on this website are personal to you and may not be transferred to any other individual or entity. You agree to utilize the services solely for legal and lawful purposes, without impersonating or misrepresenting any person or entity. All services obtained are for your use, and if you represent other individuals, companies, or entities, you must be authorized to do so. You bear sole responsibility for any consequences arising from such representation, and Netré shall not be held liable in any manner.
    //     </p>
    //     <p className="mb-4">
    //       It is your responsibility to promptly update any changes to your registration information. Please note that your access to this website may be periodically interrupted for various reasons, including equipment malfunction, website maintenance, or other actions undertaken at our discretion. While we strive to provide uninterrupted service, we do not guarantee that the website will be free from interruptions, errors, or harmful elements. By accessing the website, you agree to these terms and conditions and acknowledge that your access is at your own risk. Netré shall not be liable for any damages related to your use of, or inability to access, the website.
    //     </p>
    //   </section>

    //   {/* Data Storage, Deletion, or Transport */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Data Storage, Deletion, or Transport</h2>
    //     <p className="mb-4">
    //       Netré acknowledges that the data provided by you belongs solely and exclusively to you. Therefore, you have the right to remove or delete the data, either in full or in part, at any time as desired. We request that you notify us of any such removal or deletion. If you wish for us to remove or delete all or part of your data, please provide a written request to us. Upon receiving such a request, we will promptly comply and inform you accordingly.
    //     </p>
    //     <p className="mb-4">
    //       We assure you that we will not retain any copies of the deleted data on our servers or elsewhere. We guarantee that we cannot access such deleted material at any time. Any access, information, or contact we had with such data, material, or accounts will cease immediately. However, certain portions of your data may remain in backups or transaction logs on our servers. These are maintained solely for backup purposes or to ensure continuity of service in case of server malfunction or damage, without disrupting our services to you.
    //     </p>
    //   </section>

    //   {/* Communication */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Communication</h2>
    //     <p className="mb-4">
    //       Netré may periodically send you communications, notices, or alerts, which will be sent automatically. By using our services, you agree to receive various types of communication, including but not limited to emails, SMS, phone calls, or any other mode of communication, for transactional, promotional, or informational purposes. If you have disabled or marked our alerts or communication as junk, we recommend revising your settings to ensure receipt of important alerts. Please note that our communications will pertain to our services and will not include marketing or spam emails.
    //     </p>
    //     <p className="mb-4">
    //       Changes to your email address will apply to all alerts. Any email sent by Netré or through our website is intended solely for the addressee(s) and may be privileged. If you are not the intended recipient, you may not copy, forward, or disclose any part of the message or its attachments. If you receive a message in error, please delete it from your system and notify us immediately.
    //     </p>
    //     <p>
    //       You acknowledge that internet communications may not be secure or error-free, and information sent over the internet could be intercepted, corrupted, lost, or contain viruses. Netré does not accept responsibility for any errors or omissions in messages received due to internet transmission.
    //     </p>
    //   </section>

    //   {/* Granting of Rights */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Granting of Rights</h2>
    //     <p className="mb-4">
    //       To facilitate the provision of our services, you may provide us with various information, data, passwords, usernames, and other materials, including suggestions, ideas, and feedback. By providing this information, you expressly grant us the license and right to utilize it on your behalf to provide our services. We will use this information solely to deliver the required services and not for any other purpose.
    //     </p>
    //     <p className="mb-4">
    //       You warrant and represent that you are duly authorized to submit this information or represent the third party on whose behalf you are providing it. Furthermore, you acknowledge and agree that we may use this information, suggestions, feedback, and materials without any obligation or restriction, including for marketing, promoting, advertising, or other purposes.
    //     </p>
    //     <p className="mb-4">
    //       By using our services, you authorize us to access your accounts maintained by identified third parties as your agent. When you utilize this feature, you will be directly connected to the third-party website you have identified, and we will use the information you provide, such as usernames and passwords, to log you into the site and configure our service to be compatible with it.
    //     </p>
    //   </section>

    //   {/* Your Contributions to the Website */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Your Contributions to the Website</h2>
    //     <p className="mb-4">
    //       As part of our service, we encourage and enable you to post messages or content on publicly available forums, blogs, and other locations on the website. By using or posting messages, data, or any other information on such platforms, you acknowledge that you are solely responsible for all the content you contribute. You further represent and warrant that you have all necessary rights to post such content and grant us a perpetual, worldwide, royalty-free, non-exclusive, transferable, and sublicensable right to use, reproduce, distribute, display, modify, amend, and perform such content to promote, modify, or redistribute this website, including preparation of derivative works, in any form and through any medium, without any restrictions.
    //     </p>
    //     <p className="mb-4">
    //       You agree that these rights will also be available to other users of the website. If you choose to use forums, blogs, or any other feature on the website, it is important to make an informed decision about submitting your personally identifiable information. Please note that any personally identifiable information submitted on such platforms can be read, collected, or used by any third party, posing a risk of misuse or misappropriation. We do not control these actions and cannot be held responsible for the personally identifiable information you choose to submit on a public platform. In case of any violation of these terms, we reserve the right to terminate your participation in such public forums.
    //     </p>
    //   </section>

    //   {/* Protection of Intellectual Property */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Protection of Intellectual Property</h2>
    //     <p className="mb-4">
    //       You recognize and acknowledge that this Website and any associated software contain proprietary and confidential information protected by applicable intellectual property and other laws. This includes content or software on the Website such as its "look and feel," photographs, editorial content, notices, and other materials, which are protected by national and international copyright, trademark, service mark, patent, or other proprietary rights laws.
    //     </p>
    //     <p className="mb-4">
    //       You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on this Website or any software associated with it, in whole or in part, except as expressly authorized by the Company. The entire Website and its contents, including the software, are owned or duly licensed to the Company and protected by copyright laws in India and international treaty provisions.
    //     </p>
    //     <p className="mb-4">
    //       All trademarks, service marks, and logos displayed on this Website are registered to and/or owned by their respective owners. No license or right to use any of the trademarks, service marks, or logos displayed on the Website is granted without the prior written consent of the Company or the respective owners.
    //     </p>
    //     <p>
    //       You may download or print a copy of the information provided on this Website for your personal, internal, and non-commercial use only. Any distribution, reprint, or electronic reproduction of content from this Website for any other purpose is expressly prohibited without our prior written consent.
    //     </p>
    //   </section>
    //      {/* Prohibited Activities */}
    //      <section>
    //     <h2 className="text-2xl font-semibold mb-4">Prohibited Activities</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li>Uploading, posting, emailing, transmitting, or making available any unlawful, harmful, threatening, abusive, harassing defamatory, vulgar, obscene, or objectionable content.</li>
    //       <li>Impersonating any person or entity, or falsely stating or misrepresenting any information.</li>
    //       <li>Manipulating identifiers to disguise the origin of any content.</li>
    //       <li>Collecting or storing personal data about other users.</li>
    //       <li>Interfering with or disrupting the Website or its servers or networks.</li>
    //       <li>Violating any applicable local, state, national, or international law.</li>
    //       <li>Distributing material containing software viruses or any other computer code designed to interrupt or destroy computer software or hardware.</li>
    //       <li>Sending unsolicited or unauthorized advertising, promotional materials, "spam," or any other form of solicitation.</li>
    //       <li>Using automated data gathering tools to access the Website.</li>
    //       <li>Using any device or mechanism to navigate or search the Website, except for designated search engines and browsers.</li>
    //       <li>Attempting to reverse-engineer any software on the Website.</li>
    //       <li>Posting or transmitting any message, data, image, or program that violates the property rights of others, including unauthorized copyrighted material or trademarks.</li>
    //       <li>Interfering with other users' use of the Service, including disrupting dialogue, deleting content, or imposing a burden on the Service infrastructure.</li>
    //     </ul>
    //     <p className="mt-4">
    //       You agree that any relationships formed with employers, employees, or contractors through the Website are solely between you and them, and not with us.
    //     </p>
    //   </section>

    //   {/* Security and Privacy */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Security and Privacy</h2>
    //     <p className="mb-4">
    //       At Netré, we understand the importance of safeguarding your personal information. Your trust is invaluable to us, and we are committed to using your information responsibly. Here's how we ensure the confidentiality, integrity, and security of your data:
    //     </p>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Information You Provide:</strong> When you interact with our website, you may provide us with certain information. We do not store any personal sensitive information on our servers; it remains with you on your system. This information helps us respond to your requests, customize future transactions, improve our database, and communicate with you.</li>
    //       <li><strong>Automatic Information:</strong> We receive and store certain types of information when you interact with us. Our server logs your activities for diagnostic and analytical purposes, but we only maintain your IP address for logging purposes.</li>
    //       <li><strong>Email Communications:</strong> To enhance the utility of our emails, we may request confirmation when you open an email from us, if your computer supports such capabilities.</li>
    //       <li><strong>Sharing of Information:</strong> We may share customer information related to transactions with other businesses involved in those transactions.</li>
    //       <li><strong>Business Transfers:</strong> As we grow, we may merge with or be acquired by another company. In such cases, customer information is typically transferred as a business asset, and we'll ensure the protection of your information as per our policies.</li>
    //       <li><strong>Protection of Company and Others:</strong> We may release account and personal information when necessary to comply with the law, enforce our terms and agreements, or protect the rights, property, or safety of our users and others.</li>
    //     </ul>
    //     <p className="mt-4">
    //       By using our website, you accept the practices described in this Privacy Policy. We're proud of our privacy practices and the strength of our site security, and we're committed to providing you with the best service while respecting your privacy.
    //     </p>
    //   </section>

    //   {/* Information Security */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Information Security</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Secure Transmission:</strong> We employ Secure Sockets Layer (SSL) software to encrypt the information you provide, ensuring its security during transmission.</li>
    //       <li><strong>Privacy Policy:</strong> Your personal information is never sold or rented to anyone without your explicit consent, except in response to valid legal requests or for statistical purposes without disclosing personal data.</li>
    //       <li><strong>Restricted Access:</strong> Access to your registration information and personal financial data is strictly limited to authorized Netré employees and contractors on a need-to-know basis. Unauthorized access is subject to disciplinary action, including termination and criminal prosecution.</li>
    //       <li><strong>Protection of Personal Identity:</strong> We empower you to maintain the anonymity of your identity by allowing you to choose a login ID, email address, and password that preserves your anonymity.</li>
    //       <li><strong>Account Protection:</strong> It's crucial to guard against unauthorized access to your password and computer. Always remember to sign off when using a shared computer.</li>
    //       <li><strong>Electronic Connections:</strong> With your consent, we may establish electronic connections to your online bank, credit card, and other financial accounts to securely process your orders.</li>
    //     </ul>
    //   </section>

    //   {/* Conditions of Use, Notices, and Revisions */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Conditions of Use, Notices, and Revisions</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li>Your visit to our website and any privacy concerns are governed by our terms of agreement.</li>
    //       <li>If you have privacy concerns, please reach out to us, and we'll promptly address them.</li>
    //       <li>Our privacy policies evolve to align with our business. Be sure to check our website regularly for updates.</li>
    //     </ul>
    //     <p className="mt-4">
    //       At Netré, we are dedicated to upholding the security and confidentiality of your information, ensuring a safe and trustworthy experience for all our users.
    //     </p>
    //   </section>

    //   {/* Disclaimer */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Disclaimer</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Content and Service:</strong> Netré provides content and services on an "as-is" and "as-available" basis. We make no representations or warranties regarding the accuracy, reliability, or completeness of the content or operation of this website or the service. Your use of the service and products is at your sole risk.</li>
    //       <li><strong>No Warranty:</strong> We expressly disclaim any warranties of non-infringement or fitness for a particular purpose. While we employ methods to safeguard against viruses, we cannot guarantee that the content available through the service is free of infection from any viruses or destructive properties.</li>
    //     </ul>
    //   </section>

    //   {/* Limitation of Liability */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Indirect Damages:</strong> Netré shall not be liable for any indirect, special, incidental, consequential, exemplary, or punitive damages arising from your access to this website, use of the service, or purchase of products, even if we have been advised of the possibility of such damages.</li>
    //     </ul>
    //   </section>

    //   {/* Indemnity by You */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Indemnity by You</h2>
    //     <p className="mb-4">
    //       <strong>Defence and Indemnification:</strong> You agree to defend, indemnify, and hold harmless Netré and its officers, directors, shareholders, and employees from any loss, damages, claims, or expenses arising out of or attributable to any breach of this agreement by you or any misuse of the service.
    //     </p>
    //   </section>

    //   {/* Termination */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Termination</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Termination Conditions:</strong> This agreement is binding unless terminated by either party with a written notice of at least three business days, immediate closure of your account by either party or if you breach any terms of this agreement.</li>
    //     </ul>
    //   </section>

    //   {/* Jurisdiction */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Jurisdiction</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Governing Law:</strong> This agreement shall be governed by and construed by the laws of India. All legal proceedings shall be brought in New Delhi, India, and disputes shall be resolved by the Indian Arbitration and Conciliation Act, 1996.</li>
    //     </ul>
    //   </section>

    //   {/* Miscellaneous */}
    //   <section className="mb-12">
    //     <h2 className="text-2xl font-semibold mb-4">Miscellaneous</h2>
    //     <ul className="list-disc list-inside space-y-2">
    //       <li><strong>Fair Interpretation:</strong> The language in this agreement shall be interpreted fairly. If any part of this agreement is held invalid, the remaining portions shall remain in full force and effect.</li>
    //       <li><strong>Amendment:</strong> This agreement may only be amended in writing by both parties. In case of any issues, you may contact our grievance officer at ………….</li>
    //     </ul>
    //     <p className="mt-4">
    //       At Netré, we prioritize transparency and integrity in all our operations, and we strive to provide you with the best possible service while protecting your rights and interests.
    //     </p>
    //   </section>
    // </div>
  );
};

export default AboutUs;
