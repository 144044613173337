import { Link } from "react-router-dom";
import "./whatWeOffer.scss";

import React from "react";
import { Breakpoint } from "react-socks";

function WhatWeOffer({ homeData }) {
  return (
    <div className="What_We_Offer_Container">
      <div className="What_We_Offer_Title_Container">
        <div className="What_We_Offer_title font-sabon">
          {homeData?.feature_banners?.title}
        </div>
        <div className="What_We_Offer_Para">
          {homeData?.feature_banners?.subtitle}
        </div>
      </div>
      <Breakpoint large up>
        <div className="What_We_Offer_Gallery">
          {homeData?.feature_banners?.banners?.map((item, index) => {
            return (
              <div className="item" key={index}>
                {/* <div className="Overlay"></div> */}
                <img alt="" src={item?.desktop} />
                {/* <img alt="" src={"/new/service_1_desktop.png"}  /> */}
                <Link
                  to={item?.redirect}
                  className="try-now-btn mont-regular text-nowrap shadow-2xl"
                >
                  {item?.button_text}
                  <div className="overlay"></div>
                </Link>
              </div>
            );
          })}
        </div>
      </Breakpoint>
      <Breakpoint medium down>
        <div className="What_We_Offer_Gallery">
          {homeData?.feature_banners?.banners?.map((item, index) => {
            return (
              <div
                className="h-auto w-full aspect-[1/1] mobile_item"
                key={index}
                style={{
                  backgroundImage: `url(${item?.mobile})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <div className="Overlay"></div> */}
                {/* <img alt="" src={item?.mobile} /> */}
                {/* <img alt="" src={"/new/service_1_desktop.png"}  /> */}
                <Link
                  to={item?.redirect}
                  className="try-now-btn mont-regular text-nowrap shadow-2xl"
                >
                  {item?.button_text}
                  <div className="overlay"></div>
                </Link>
              </div>
            );
          })}
        </div>
      </Breakpoint>
      {/* <div className="What_We_Offer_Gallery"> */}

      {/* </div> */}
    </div>
  );
}

export default WhatWeOffer;
{
  /* <div className="item" key={index}>
<div className="Overlay"></div>
<img alt="" src={item?.mobile} className="w-full h-full" />
<Link
  to={item?.redirect}
  className="try-now-btn mont-regular text-nowrap"
>
  {item?.button_text}
  <div className="overlay"></div>
</Link>
</div> */
}
