import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useGetUser from "../../../../hooks/useGetUser";
import {
  useAddToCustomerBagMutation,
  useAddToCustomerHomeTryOnDataMutation,
  useAddToWishlistMutation,
  useRemoveFromWishlistMutation,
} from "../../../../redux/slices/homeSlice";
import { useNavigate } from "react-router-dom";
import { addToMyBag } from "../../../../redux/slices/myBagSlice";
import CLOSE_BLACK_IC from "../../../../assets/svg/close_black_ic.svg";
import MODEL from "../../../Master/MODEL";
import BTN from "../../../Master/BTN";
import RatingCard from "../../../Master/RatingCard";
import { BAG_IC } from "../../../../assets";
import { useFormik } from "formik";
import INPUT from "../../../Master/INPUT";

export default function SliderChild({ data, blueCutLensIdDataLabel }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (data?._id) {
        const found = wishlistData.find((element) => element._id === data._id);
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, data]);

  const user = useGetUser();
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!data._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: data._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: data._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        console.log(res);
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };

  const redirctUrl = `/products/${data?._id}`;
  var image = null;

  image = data?.image[0] ? data?.image[0] : null;
  if (image) {
    var transformedImage = image.replace(
      "upload/",
      "upload/if_w_gt_400/w_400/if_end/"
    );
  }

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(data));
      toast.success(`${data?.name} added to bag successfully.`);
    }
  };

  // =================== ADD CART HANDLE =================
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: data?._id },
      });
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  // ====================== ADD ZERO POWER LENS HANDLE ================================

  const addZeroPowerLensHandle = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: 1,
              variant: [],
              zeroPower: true,
              lens: [
                {
                  lens_id: blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                  addons: [],
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: {
          forZeroPowerLensId: data?._id,
          isOnlyLens: false,
        },
      });
    }
    setLensAddModelToggle(false);
  };

  const calculateDiscountPercentage = (originalPrice, discount) => {
    return Math.round(((discount / originalPrice) * 100).toFixed(2)); // 2 decimal places
  };

  // ====================== New arrival ================================
  const [showNewArrival, setShowNewArrival] = useState(false);
  const interval = 1000 * 60 * 60 * 24 * 30; // 30 days
  useEffect(() => {
    if (data?.createdAt) {
      const productDate = new Date(data?.createdAt);
      const currentDate = new Date();
      const diff = currentDate - productDate;
      if (diff < interval) {
        setShowNewArrival(true);
      }
    }
  }, [data?.createdAt]);

  // ================== VERIFY PIN CODE AND ADD TO HOME TRYON =====================

  const [pinCodeVerifyId, setPinCodeVerifyId] = useState("");
  const [addToCustomerHomeTryOnData] = useAddToCustomerHomeTryOnDataMutation();

  const addToHomeTryOnHandel = (productId) => {
    console.log("productId", productId);
    addToCustomerHomeTryOnData({
      body: {
        products: [
          {
            product_id: productId,
            variant: [],
          },
        ],
      },
      user: localStorage.getItem("user"),
    }).then((res) => {
      if (res?.data) {
        toast.success(`added to home try-on successfully.`);
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  };

  const pincodeFormik = useFormik({
    initialValues: {
      pincode: "",
    },
    onSubmit: async function (values) {
      console.log("Submitting form...");

      const myHeaders = new Headers();
      myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
      myHeaders.append("Authorization", localStorage.getItem("user"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}netre/checkHtoAvailability/${values.pincode}`,
          requestOptions
        );
        const result = await response.json();

        if (result?.isServiceable === true) {
          localStorage.setItem("isServiceable", true);
          addToHomeTryOnHandel(pinCodeVerifyId);
          setPinCodeVerifyId("");
          pincodeFormik.resetForm();
        } else {
          toast.error("Currently Service is not available in your city!");
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values?.pincode) {
        errors.pincode = "Pin code is Required!";
      } else if (values?.pincode && values?.pincode?.length !== 6) {
        errors.pincode = "Pincode must be 6 digits!";
      }
      return errors;
    },
  });

  return (
    <div>
      <div className="child hover:shadow-md border-[0.5px] border-light_secondary  cursor-pointer lg:min-h-[22vw] emd:min-h-[35vw] small:min-h-[75vw] lg:max-h-[22vw] emd:max-h-[35vw] small:max-h-[75vw] lg:min-w-[22vw] emd:min-w-[35vw] small:min-w-[70vw] lg:max-w-[22vw] emd:max-w-[35vw] small:max-w-[70vw] ">
        <div className="top relative">
          <div
            style={{ visibility: showNewArrival ? "visible" : "hidden" }}
            className="new-arrival-label mont-bold absolute lg:top-0 lg:-left-6 emd:-top-1.5 emd:-left-6 small:-top-0 small:-left-5 bg-primary  text-white px-4 py-1"
          >
            NEW ARRIVAL
          </div>
          {calculateDiscountPercentage(
            data?.prices?.originalPrice,
            data?.prices?.discount
          ) !== 0 &&
            !showNewArrival && (
              <div className="offer_ribbon leading-[1.1em] py-1.5 flex flex-col lg:px-2 emd:px-2 small:px-1 lg:text-[12px] emd:text-[10px] small:text-[8px] lg:-top-6 lg:-left-5 emd:-top-6 emd:-left-5 small:-top-5 small:-left-4 bg-primary mont-bold text-white text-center">
                {calculateDiscountPercentage(
                  data?.prices?.originalPrice,
                  data?.prices?.discount
                )}
                %
                <span className="lg:text-[10px] emd:text-[8px] small:text-[6px] mont-bold text-white">
                  Off
                </span>
              </div>
            )}
          {wishListed ? (
            <svg
              onClick={handleWishlist}
              className="pointer filled min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]"
              viewBox="0 -960 960 960"
              fill="#5f6368"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="min-w-fit max-w-fit min-h-fit max-h-fit"
                d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
                transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
              />
            </svg>
          ) : (
            <svg
              onClick={handleWishlist}
              className="pointer min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5f6368"
            >
              <path
                className="min-w-fit max-w-fit min-h-fit max-h-fit"
                d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z"
              />
            </svg>
          )}
          <img
            src={BAG_IC}
            alt=""
            className="min-w-6 max-w-6 object-contain lg:ms-1 emd:ms-1 small:ms-0 cursor-pointer"
            onClick={() => {
              data?.isAccessories === true
                ? addCartHandle(false)
                : setLensAddModelToggle(true);
            }}
          />
        </div>
        <img
          src={transformedImage ? transformedImage : "/Netre-logo.svg"}
          height="100%"
          width="100%"
          alt=""
          onClick={() => {
            navigate(redirctUrl);
          }}
          className={`${transformedImage ? "cover" : "contain"}`}
        />
        <div className="flex flex-col w-full">
          <div
            onClick={() => {
              navigate(redirctUrl);
            }}
            className="Product_name emd:text-sm small:text-sm lg:text-lg mont-regular w-full text-ellipsis overflow-hidden"
          >
            {data?.name}
          </div>
          <div
            onClick={() => {
              navigate(redirctUrl);
            }}
            className="my-3 emd:text-sm small:text-sm lg:text-base"
          >
            {/* ₹ {data?.prices?.price} */}
            {Math.round(data?.prices?.discount) !== 0 ? (
              <div className="flex items-center">
                <div className="line-through text-light_secondary text-nowrap">
                  {data?.prices?.originalPrice}
                </div>
                <div className="ms-2 text-nowrap mont-bold">
                  ₹ {data?.prices?.price}
                </div>
              </div>
            ) : (
              <div className="mont-bold">₹ {data?.prices?.price}</div>
            )}
          </div>
          <div className="w-full flex items-center justify-between">
            {!data?.isAccessories ? (
              <span
                className="text-primary emd:text-sm small:text-sm lg:text-base"
                onClick={() => {
                  if (!loggedIn) {
                    toast.error("Please log in to add into Home Tryon");
                    return;
                  } else if (data?.isAddedInHto) {
                    navigate("/shopping-bag", { state: { isHomeTryOn: true } });
                  } else if (localStorage.getItem("isServiceable")) {
                    addToHomeTryOnHandel(data?._id);
                  } else {
                    setPinCodeVerifyId(data?._id);
                  }
                }}
              >
                {data?.isAddedInHto ? "Go to Home Tryon" : "Add to Home Tryon"}
              </span>
            ):<></>}
            {data?.totalReviews > 0 && (
              <RatingCard
                rating={data?.averageRating}
                totalRating={data?.totalReviews}
              />
            )}
          </div>
        </div>
      </div>
      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setLensAddModelToggle(false);
              }}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <span
              onClick={() => {
                addCartHandle(true);
              }}
              className={
                "w-full mont-regular cursor-pointer text-nowrap lg:text-base emd:text-sm small:text-sm py-1 px-3 bg-secondary text-white text-center"
              }
            >
              Add Lens With Power
            </span>
            <span
              onClick={() => {
                addZeroPowerLensHandle();
              }}
              className={
                "w-full cursor-pointer text-nowrap mt-5 mont-regular lg:text-base emd:text-sm small:text-sm py-1 px-3 bg-white text-secondary border-secondary border text-center"
              }
            >
              Add Lens With Zero Power
            </span>

            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              className="text-primary text-nowrap cursor-pointer text-center lg:text-base emd:text-sm small:text-sm"
              onClick={() => addCartHandle(false)}
            >
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>
      {/* =================== VERIFY PIN-CODE MODEL =============== */}
      <MODEL
        setShowModel={() => setPinCodeVerifyId("")}
        showModel={pinCodeVerifyId !== ""}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Verify PinCode</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setPinCodeVerifyId("")}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <INPUT
              placeHolder={"Enter Your PinCode"}
              name="pincode"
              onChange={pincodeFormik.handleChange}
              val={pincodeFormik.values.pincode}
              onBlur={pincodeFormik.handleBlur}
              errorMsg={pincodeFormik.errors.pincode}
              touched={pincodeFormik.touched.pincode}
            />
            <button
              type="submit"
              className={
                "w-full outline-none border-none mont-bold text-lg py-1 px-3 mt-5 lg:text-base emd:text-sm small:text-sm"
              }
              style={{
                backgroundColor: "#444444",
                fontSize: "14px",
                color: "white",
              }}
              onClick={pincodeFormik.handleSubmit}
            >
              Verify
            </button>
          </div>
        </div>
      </MODEL>
    </div>
  );
}
