import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Utilities/BreadCrumbs/Breadcrumbs";
import "./ShoppingBag.scss";
import {
  ARROW_FORWARD_BLACK_IC,
  BOX_IMG,
  CALL_IC,
  CLOSE_BLACK_IC,
  CLOSE_IC_18,
  DELETE_IC,
  OFFER_TAG_IC,
} from "../../../assets";
import ShoppingBagProductCard from "./ShoppingBagProductCard";
import Accordion from "./Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../hooks/useGetUser";
import {
  addOrderDetails,
  removeOrderDetails,
} from "../../../redux/slices/myBagSlice";
import MODEL from "../../Master/MODEL";
import { toast } from "react-toastify";
import {
  useAddToCustomerHomeTryOnOrderDataMutation,
  useGetCouponListQuery,
  useGetUserProfileQuery,
  useRemoveFromCustomerHomeTryOnDataMutation,
} from "../../../redux/slices/homeSlice";
import BTN from "../../Master/BTN";
import LtoRScrollListing from "../../Master/LtoRScrollListing";
import ShoppingBagProductCardNew from "./ShoppingBagProductCardNew";
import INPUT from "../../Master/INPUT";
import { useFormik } from "formik";
import { formatCurrency } from "../../../functions";

const ShoppingBag = ({ setLensStep, blueCutLensIdDataLabel }) => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Shopping Bag", link: "/shopping-bag" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const recentProducts = useSelector(
    (state) => state.recentProducts.recentProducts
  );

  const user = useGetUser();
  const dispatch = useDispatch();
  const myBagitems = useSelector((state) => state.myBagData.items);
  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);

  const homeTryOnData = useSelector((state) => state.homeTryOnData.items);

  const [traySectionToggle, setTraySectionToggle] = useState(
    location?.state?.isHomeTryOn ? false : true
  );
  const [couponsToggle, setCouponsToggle] = useState(false);
  const accordionData = [
    {
      title: "Contact Us",
      content: "Netre Contact Us",
    },
    {
      title: "Shopping and Returns",
      content: "Netre Shopping and Returns",
    },
    {
      title: "Payment and secure Transactions",
      content: "Netre Payment and secure Transactions",
    },
    {
      title: "After care",
      content: "Netre After care",
    },
  ];

  const {
    data: customerProfileData,
    isSuccess: customerProfileDataIsSuccess,
    isLoading: customerProfileDataIsLoading,
    isError: customerProfileDataIsError,
    error: customerProfileDataError,
  } = useGetUserProfileQuery(
    { user: localStorage.getItem("user") },
    { skip: !user }
  );

  const subTotal = () => {
    return myBagitems?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        // Add prices of all addons for this lens
        const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
          return addonsSum + addon.price;
        }, 0);
        return addonsPrice + lensTotal + lens?.price || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  // const total = myBagitems.reduce(
  //   (acc, item) =>
  //     acc + item.prices.price * item.quantity + (item?.lensDetails?.price || 0),
  //   0
  // );

  const {
    data: couponData,
    isSuccess: couponDataIsSuccess,
    isLoading: couponDataIsLoading,
    isError: couponDataIsError,
    error: couponDataError,
  } = useGetCouponListQuery({ user: user, couponType: "online" });

  const formateDate = (date) => {
    const estimatedDeliveryDate = new Date(date);
    estimatedDeliveryDate.setDate(estimatedDeliveryDate.getDate());
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate.toLocaleDateString("en-GB", options);
    return formattedEstimatedDeliveryDate;
  };

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Coupon code copied to clipboard!");
      })
      .catch((err) => {
        toast.success("Failed to copy text: ");
      });
  };

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      toast.error("Your coupon was expired!");
      return false;
    }
    if (subTotal() < coupon?.minimumAmount) {
      toast.error(
        `This coupon is valid for minimum order ${coupon?.minimumAmount}!`
      );
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      toast.error(`This coupon's quantity is ${coupon?.couponQuantity}!`);
      return false;
    }
    return true;
  };

  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return true;
    } else {
      return false;
    }
  };

  const calculateCouponOffer = (coupon) => {
    if (coupon) {
      if (coupon?.discountType?.type === "fixed") {
        return subTotal() - coupon?.discountType?.value;
      } else {
        return Math.round(
          parseFloat(
            subTotal() - (subTotal() * coupon?.discountType?.value) / 100
          ).toFixed(2)
        );
      }
    } else {
      return subTotal();
    }
  };

  const couponApplyHandle = (item) => {
    if (checkCouponCodeIsValid(item)) {
      dispatch(
        addOrderDetails({
          ...orderDetails,
          couponCode: item,
        })
      );
      setCouponsToggle(false);
      toast.success(`${item?.couponCode} applied`);
    }
  };

  const isNotGo = () => {
    return myBagitems?.some((item) =>
      item?.quantity < item?.lensDetails?.length ? true : false
    );
  };

  const goCheckOutHandle = () => {
    if (isNotGo()) {
      toast.error("Your lens quantities are more then Frames quantities!", {
        autoClose: 4000,
      });
      return;
    }
    navigate(user?.customer ? "/shopping-tray" : "/signup", {
      state: { isNewUser: user?.customer ? false : true },
    });
  };

  // ====================== HOME TRY-ON ADDRESS =====================
  const [isSelected, setIsSelected] = useState("date_time"); //address
  const [homeTryOnBody, setHomeTryOnBody] = useState(null);

  const [selectHomeTryAddressToggle, setSelectHomeTryAddressToggle] =
    useState(false);
  const [selectedHomeTryAddress, setSelectedHomeTryAddress] = useState(null);

  const [addToCustomerHomeTryOnOrderData] =
    useAddToCustomerHomeTryOnOrderDataMutation();

  const homeTryCalculatePrice = () => {
    return homeTryOnData?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price;
      return total + productPrice;
    }, 0);
  };

  const dateHomeTryFormik = useFormik({
    initialValues: {
      deliveryDate: "",
      deliveryTime: "",
    },
    onSubmit: async function (values) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // This makes the scroll smooth
      });
      setHomeTryOnBody({
        ...homeTryOnBody,
        deliveryDate: values?.deliveryDate,
        deliveryTime: values?.deliveryTime,
      });
      setIsSelected("address");
    },
    validate: (values) => {
      let errors = {};
      if (!values?.deliveryDate) {
        errors.deliveryDate = "Date is Required!";
      } else if (!values?.deliveryTime) {
        errors.deliveryTime = "Time is Required!";
      }
      return errors;
    },
  });

  const addressHomeTryFormik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      country: "",
      phone: "",
      email: "",
      label: "",
    },
    onSubmit: async function (values) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // This makes the scroll smooth
      });

      addToCustomerHomeTryOnOrderData({
        body: {
          ...homeTryOnBody,
          products: homeTryOnData,
          type: "Home",
          phoneNumber: customerProfileData?.customer?.phone,
          address: selectedHomeTryAddress
            ? {
                ...selectedHomeTryAddress,
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              }
            : {
                address1: values?.address1,
                address2: values?.address2,
                city: values?.city,
                country: values?.country,
                firstname: values?.firstname,
                label: values?.label,
                lastname: values?.lastname,
                phone: values?.phone,
                zipCode: values?.zipCode,
              },
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Order added successfully.`);
          navigate("/profile-details#order-history", {
            state: { orderTypeHome: "home" },
          });
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.firstname) {
        errors.firstname = "First Name is Required!";
      } else if (!values?.lastname) {
        errors.lastname = "Last Name is Required!";
      } else if (!values?.address1) {
        errors.address1 = "Address One is Required!";
      } else if (!values?.zipCode) {
        errors.zipCode = "Pin Code is Required!";
      } else if (!values?.city) {
        errors.city = "City is Required!";
      } else if (!values?.country) {
        errors.country = "Country is Required!";
      } else if (!values?.phone) {
        errors.phone = "Phone Number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      }
      return errors;
    },
  });

  const homeTryAddressSelectHandle = (item) => {
    setSelectedHomeTryAddress(item);
    setSelectHomeTryAddressToggle(false);
    addressHomeTryFormik.setValues({
      ...addressHomeTryFormik.values,
      address1: item?.address1,
      address2: item?.address2,
      zipCode: item?.zipCode,
      phone: item?.phone,
      city: item?.city,
      country: item?.country,
      label: item?.label,
      firstname: item?.firstname,
      lastname: item?.lastname,
    });
  };
  

  const [dateIsCompleted, setDateIsCompleted] = useState(false);
  const [addressIsCompleted, setAddressIsCompleted] = useState(false);
  useEffect(() => {
    dateHomeTryFormik?.values?.deliveryDate !== "" &&
    dateHomeTryFormik?.values?.deliveryTime !== ""
      ? setDateIsCompleted(true)
      : setDateIsCompleted(false);

    addressHomeTryFormik?.values?.firstname !== "" &&
    addressHomeTryFormik?.values?.lastname !== "" &&
    addressHomeTryFormik?.values?.address1 !== "" &&
    addressHomeTryFormik?.values?.zipCode !== "" &&
    addressHomeTryFormik?.values?.city !== "" &&
    addressHomeTryFormik?.values?.country !== ""
      ? setAddressIsCompleted(true)
      : setAddressIsCompleted(false);
  }, [dateHomeTryFormik, addressHomeTryFormik]);

  const frameTotal = myBagitems?.reduce((total, item) => {
    // Calculate the product price based on quantity
    const productPrice = item?.prices?.price * item?.quantity;

    // Calculate the total lens price for this product (sum of all lenses)

    return total + productPrice;
  }, 0);

  const lensTotal = myBagitems?.reduce((total, item) => {
    const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
      return lensTotal + lens?.price || 0;
    }, 0);

    return total + totalLensPrice;
  }, 0);

  const addonTotal = myBagitems?.reduce((total, item) => {
    const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
      const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
        return addonsSum + addon.price;
      }, 0);
      return lensTotal + addonsPrice;
    }, 0);
    return total + totalLensPrice;
  }, 0);
  
  return (
    <div className="shopping_bag_main">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="sabon cursor-pointer flex items-center bg-gray p-3">
        <div
          className={` ${
            traySectionToggle
              ? "bg-white text-primary border-[0.5px] border-[#dfd6d6]"
              : ""
          } shopping_tray_div cursor-pointer lg:text-2xl emd:text-xl small:text-lg select-none p-2 text-nowrap`}
          onClick={() => setTraySectionToggle(true)}
        >
          Shopping Tray
        </div>
        <div
          className={` ${
            !traySectionToggle
              ? "bg-white text-primary border-[0.5px] border-[#dfd6d6]"
              : ""
          } select-none cursor-pointer trial_tray_div lg:text-2xl emd:text-xl small:text-lg py-2 px-10 text-nowrap`}
          onClick={() => setTraySectionToggle(false)}
        >
          Trial Tray
        </div>
      </div>
      {traySectionToggle ? (
        <div className="shopping_tray_main">
          <div className="shopping_tray_one">
            <div
              className={` ${
                myBagitems?.length === 0 ? "w-full" : "w-[60%]"
              } left_top_div`}
            >
              {/* <div className={` left_top_div`}> */}
              {myBagitems?.length === 0 ? (
                <div className="w-full flex-col my-52 flex items-center justify-center">
                  <span className="text-light_secondary text-2xl">
                    Your Bag is empty!
                  </span>
                  <BTN
                    className={
                      "mt-16 py-1 px-4 lg:w-auto md:w-auto small:w-fit"
                    }
                    title="Start shopping"
                    bordered
                    to={"/search"}
                  />
                </div>
              ) : (
                myBagitems.map((item, index) => {
                  return (
                    <ShoppingBagProductCardNew
                      key={index}
                      data={item}
                      setLensStep={setLensStep}
                      blueCutLensIdDataLabel={blueCutLensIdDataLabel}
                    />
                  );
                })
              )}
              {/* myBagitems.map((item, index) => {
                  return (
                    <ShoppingBagProductCard
                      key={index}
                      product={item}
                      setLensStep={setLensStep}
                    />
                  );
                }) */}

              {/* netre care  */}
              {/* <div className="radio_selection_section">
                <div className="selection_title_one">
                  <label className="radio_heading_text" htmlFor="option1">
                    Buy Netre Care (₹600)
                  </label>
                  <input
                    type="radio"
                    className="radio_btn"
                    id="option1"
                    checked={isGift === "option1"}
                    onChange={() => setIsGift("option1")}
                  />
                </div>
                <div className="radio_desc">
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </div>
              </div> */}
              {myBagitems?.length !== 0 && (
                <div className="radio_selection_section">
                  <div className="selection_title_one">
                    <label
                      className="radio_heading_text select-none"
                      htmlFor="gift"
                    >
                      Is this a gift ?
                    </label>
                    <input
                      type="checkbox"
                      className="radio_btn"
                      id="gift"
                      checked={orderDetails?.isGift}
                      // onChange={(e) => setIsGift(e.target.checked)}
                      onChange={(e) =>
                        dispatch(
                          addOrderDetails({
                            ...orderDetails,
                            isGift: e.target.checked,
                          })
                        )
                      }
                    />
                  </div>
                  {orderDetails?.isGift && (
                    <div className="radio_desc">
                      <div className="gift_message">
                        <span>Gift Message (Optional)</span>
                        <textarea
                          rows={3}
                          className="gift_message_textarea"
                          placeholder="Message"
                          value={orderDetails?.giftMessage}
                          onChange={(e) =>
                            dispatch(
                              addOrderDetails({
                                ...orderDetails,
                                giftMessage: e.target.value,
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            {myBagitems?.length !== 0 && (
              <div className="right_bottom_div">
                <div className="checkout_card_main">
                  <span className="checkout_card_heading">Order Summary</span>

                  <div className="w-full flex items-center justify-between mt-3">
                    <span>Frames</span>
                    <span>₹{frameTotal}</span>
                  </div>
                  {lensTotal !== 0 && (
                    <div className="w-full flex items-center justify-between">
                      <span>Lens</span>
                      <span>₹{lensTotal}</span>
                    </div>
                  )}
                  {addonTotal !== 0 && (
                    <div className="w-full flex items-center justify-between">
                      <span>Addons</span>
                      <span>₹{addonTotal}</span>
                    </div>
                  )}
                  <div className="w-full border-b border-dashed border-light_secondary my-2"></div>

                  <div className="title_one_div mt-2">
                    <span className="bill_title">Sub total</span>
                    <span className="bill_title">₹{subTotal()}</span>
                  </div>
                  <div className="title_two_div">
                    <span className="bill_title">Delivery</span>
                    <span className="bill_title">FREE</span>
                  </div>
                  {orderDetails?.isGift && (
                    <div className="title_two_div">
                      <span className="bill_title">Gift Wrap</span>
                      <span className="bill_title">FREE</span>
                    </div>
                  )}
                  <div className="title_two_div">
                    <span className="bill_title">Offer discount</span>
                    <span className="bill_title">
                      - ₹
                      {Math.round(
                        parseFloat(
                          subTotal() -
                            calculateCouponOffer(orderDetails?.couponCode)
                        )?.toFixed(2)
                      )}
                    </span>
                  </div>
                  {orderDetails?.couponCode && (
                    <span className="offers_label mb-2">Coupon Code:</span>
                  )}
                  {orderDetails?.couponCode && (
                    <div className="offers_div">
                      <div className="offer_chip_div">
                        <div className="offer_chip">
                          <img src={OFFER_TAG_IC} alt="" />
                          <span className="offer_chip_title">
                            {orderDetails?.couponCode?.couponCode}
                          </span>
                          <img
                            onClick={() =>
                              dispatch(
                                removeOrderDetails({
                                  items: myBagitems,
                                  orderDetails: {
                                    isGift: orderDetails?.isGift,
                                    giftMessage: orderDetails?.giftMessage,
                                    couponCode: null,
                                  },
                                })
                              )
                            }
                            src={CLOSE_IC_18}
                            alt=""
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <span
                    className="view_offers"
                    onClick={() => setCouponsToggle(true)}
                  >
                    View offers
                  </span>
                  <hr className="hr_checkout" />
                  <div className="total_div">
                    <span className="total_title mont-bold">Total amount</span>
                    <span className="total_title mont-bold">
                      ₹
                      {formatCurrency(
                        calculateCouponOffer(orderDetails?.couponCode)
                      )}
                    </span>
                  </div>
                  <button
                    disabled={myBagitems?.length === 0}
                    className="checkout_btn"
                    onClick={() => goCheckOutHandle()}
                  >
                    Checkout
                  </button>
                </div>
                <div className="experience_div">
                  <span className="experience_heder">The Netre Experience</span>
                  <div>
                    {accordionData?.map((item, index) => {
                      return (
                        <Accordion
                          key={index}
                          title={item?.title}
                          content={item?.content}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="box_div">
            <span className="box_title">
              Every purchase of eyewear comes with an iconic Netre box
            </span>
            <img src={BOX_IMG} alt="" className="box_img" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {homeTryOnData?.length === 0 ? (
            <div className="w-full flex-col my-52 flex items-center justify-center">
              <span className="text-light_secondary text-2xl">
                Your Trial Tray is empty!
              </span>
              <BTN
                className={"mt-16 py-1 px-4 lg:w-auto md:w-auto small:w-fit"}
                title="Start shopping"
                bordered
                to={"/search"}
              />
            </div>
          ) : (
            <div className="w-full flex justify-between mt-6 emd:flex-col lg:flex-row flex-wrap">
              <div className="flex flex-col lg:w-[55%] emd:w-full small:w-full">
                {/* =================== SELECT DATE AND TIME HOME TRY-ON =============== */}
                <span className="text-xl font-bold">Try At Home</span>
                {/* <span className="text-sm mt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Doloremque eveniet quo ad ut earum? Rerum nulla illum pariatur
                  veritatis facere sit optio.{" "}
                </span> */}
                <div className="w-full bg-gray flex flex-col mt-5 p-5">
                  <div
                    className="w-full flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      setIsSelected(
                        isSelected !== "date_time" ? "date_time" : ""
                      )
                    }
                  >
                    <span className="text-xl cursor-pointer select-none">
                      1. Date and Time
                    </span>
                  </div>
                  {isSelected === "date_time" && (
                    <div className="flex flex-col">
                      <div className="w-full flex justify-between mt-5">
                        <div className="w-[49%]">
                          <INPUT
                            minDate={new Date().toISOString().split("T")[0]}
                            maxDate={
                              new Date(
                                new Date().setDate(new Date().getDate() + 7)
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            placeHolder={"Select Date"}
                            type={"date"}
                            name="deliveryDate"
                            onChange={dateHomeTryFormik.handleChange}
                            val={dateHomeTryFormik.values.deliveryDate}
                            onBlur={dateHomeTryFormik.handleBlur}
                            errorMsg={dateHomeTryFormik.errors.deliveryDate}
                            touched={dateHomeTryFormik.touched.deliveryDate}
                          />
                        </div>
                        <div className="w-[49%]">
                          <select
                            className="INPUT w-full bg-transparent outline-none"
                            name="deliveryTime"
                            id="time_select"
                            onChange={dateHomeTryFormik.handleChange}
                            val={dateHomeTryFormik.values.deliveryTime}
                            onBlur={dateHomeTryFormik.handleBlur}
                            touched={dateHomeTryFormik.touched.deliveryTime}
                          >
                            <option value="" disabled selected>
                              Select Time
                            </option>
                            <option value="12:00 PM to 03:00 PM">
                              10:00 PM to 03:00 PM
                            </option>
                            <option value="03:00 PM to 06:00 PM">
                              03:00 PM to 07:00 PM
                            </option>
                            {/* <option value="06:00 PM to 09:00 PM">
                              06:00 PM to 09:00 PM
                            </option> */}
                          </select>
                          {dateHomeTryFormik.errors.deliveryTime &&
                          dateHomeTryFormik.touched.deliveryTime ? (
                            <span className={` text-red-600`}>
                              {dateHomeTryFormik.errors.deliveryTime}
                            </span>
                          ) : (
                            <span className={`mb-[22px]`}></span>
                          )}
                        </div>
                      </div>
                      <BTN
                        title={"CONTINUE"}
                        bordered
                        className="border-border_color text-base text-center px-1 rounded-none text-border_color mt-10 w-[48%] py-1 bg-gray"
                        onP={() => dateHomeTryFormik?.handleSubmit()}
                      />
                    </div>
                  )}
                </div>
                {/* ===================== ADDRESS SECTION HOME TRY-ON ==================  */}
                <div className="w-full bg-gray flex flex-col mt-5 p-5">
                  <div
                    className="w-full flex items-center justify-between cursor-pointer"
                    onClick={() => {
                      dateIsCompleted && dateHomeTryFormik.handleSubmit();
                      dateIsCompleted &&
                        setIsSelected(
                          isSelected !== "address" ? "address" : ""
                        );
                    }}
                  >
                    <span className="text-xl cursor-pointer select-none">
                      2. Add Address
                    </span>
                  </div>
                  {isSelected === "address" && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        addressHomeTryFormik.handleSubmit();
                      }}
                      className="w-full flex flex-col items-start"
                    >
                      <div className="w-full mt-3 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"First Name"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="firstname"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.firstname}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.firstname}
                          touched={addressHomeTryFormik.touched.firstname}
                        />
                        <INPUT
                          placeHolder={"Last Name"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="lastname"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.lastname}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.lastname}
                          touched={addressHomeTryFormik.touched.lastname}
                        />
                      </div>
                      <INPUT
                        placeHolder={"Address 1"}
                        mainClass={"mt-2"}
                        name="address1"
                        onChange={addressHomeTryFormik.handleChange}
                        val={addressHomeTryFormik.values.address1}
                        onBlur={addressHomeTryFormik.handleBlur}
                        errorMsg={addressHomeTryFormik.errors.address1}
                        touched={addressHomeTryFormik.touched.address1}
                        rComp={
                          user && (
                            <span
                              className="underline text-primary select-none cursor-pointer"
                              onClick={() =>
                                setSelectHomeTryAddressToggle(true)
                              }
                            >
                              Select
                            </span>
                          )
                        }
                      />
                      <INPUT
                        placeHolder={"Address 2 (Optional)"}
                        mainClass={"mt-2"}
                        name="address2"
                        onChange={addressHomeTryFormik.handleChange}
                        val={addressHomeTryFormik.values.address2}
                        touched={addressHomeTryFormik.touched.address2}
                      />
                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Pin code"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="zipCode"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.zipCode}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.zipCode}
                          touched={addressHomeTryFormik.touched.zipCode}
                        />
                        <INPUT
                          placeHolder={"City"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="city"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.city}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.city}
                          touched={addressHomeTryFormik.touched.city}
                        />
                      </div>

                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Country"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="country"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.country}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.country}
                          touched={addressHomeTryFormik.touched.country}
                        />
                        <INPUT
                          placeHolder={"Email"}
                          mainClass={
                            "lg:w-[48%] emd:w-full small:w-full small:mt-2 emd:mt-2 lg:mt-0"
                          }
                          name="email"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.email}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.email}
                          touched={addressHomeTryFormik.touched.email}
                        />
                      </div>

                      <div className="w-full mt-2 flex lg:flex-row emd:flex-col small:flex-col items-center justify-between">
                        <INPUT
                          placeHolder={"Ph. No."}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="phone"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.phone}
                          onBlur={addressHomeTryFormik.handleBlur}
                          errorMsg={addressHomeTryFormik.errors.phone}
                          touched={addressHomeTryFormik.touched.phone}
                        />

                        <INPUT
                          placeHolder={"Save as ( home, work )"}
                          mainClass={"lg:w-[48%] emd:w-full small:w-full"}
                          name="label"
                          onChange={addressHomeTryFormik.handleChange}
                          val={addressHomeTryFormik.values.label}
                          onBlur={addressHomeTryFormik.handleBlur}
                          // errorMsg={addressHomeTryFormik.errors.label}
                          touched={addressHomeTryFormik.touched.label}
                        />
                      </div>

                      <BTN
                        title={"CONTINUE"}
                        bordered
                        type="submit"
                        onP={addressHomeTryFormik.handleSubmit}
                        className="border-border_color text-base text-center px-1 rounded-none text-border_color mt-7 w-[48%] py-1 bg-gray"
                      />
                    </form>
                  )}
                </div>
              </div>
              {/* ======================== PAYMENT DETAILS ===================== */}
              <div className="flex flex-col lg:w-[40%] emd:w-full small:w-full lg:mt-0 emd:mt-5 small:mt-5  bg-gray p-5">
                <div className="lg:w-full emd:w-full small:w-full small:mt-5 emd:mt-5 lg:mt-0 bg-gray flex flex-col p-5">
                  <span className="text-xl">Order Summary</span>
                  {homeTryOnData?.map((item, index) => {
                    return (
                      <OrderSummaryProductCard key={index} product={item} />
                    );
                  })}
                  <div className="w-full flex flex-col items-center mt-8 pb-3 border-b border-secondary"></div>
                  <div className="w-full mt-3 flex items-center justify-between">
                    <span className="font-semibold text-lg">Total amount</span>
                    <span>
                      ₹{formatCurrency(Math.round(homeTryCalculatePrice()))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {traySectionToggle && (
        <LtoRScrollListing
          title={"Recently Viewed"}
          classForTitle="color-2"
          data={recentProducts}
          blueCutLensIdDataLabel={blueCutLensIdDataLabel}
        />
      )}
      {/* ============================== COUPON MODEL ====================== */}
      <MODEL
        showModel={couponsToggle}
        setShowModel={setCouponsToggle}
        title="Select coupon"
      >
        <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto lg:px-10 emd:px-5 small:px-4 pb-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
          {couponData?.map((item, index) => {
            return item?.status === "show" ? (
              <div
                key={index}
                className={`${
                  orderDetails?.couponCode?.couponCode === item?.couponCode
                    ? "border-primary border-2"
                    : "border-[#8B94B2] border"
                } p-4 select-none lg:h-64 emd:h-56 small:h-48 min-w-full max-w-full flex flex-col justify-between ${
                  checkEndDateOfCoupon(item) ? "opacity-50" : ""
                } `}
              >
                <div className="w-full flex items-center">
                  {/* <span className="text-2xl font-bold">40% OFF</span>
                  <span className="text-lg">For whole order</span> */}
                  <span className="text-2xl font-bold text-primary">
                    {item?.couponCode}
                  </span>
                  {/* <div
                    onClick={() => handleCopyClick(item?.couponCode)}
                    className="flex items-center ms-3 cursor-pointer"
                  >
                    <div className="material-symbols-outlined text-xl text-primary">
                      content_copy
                    </div>
                    <span className="ms-2 text-primary">Copy</span>
                  </div> */}
                </div>
                <div className="flex items-center">
                  <ul>
                    <li className="text-light_secondary text-sm">
                      {item?.title?.en}
                    </li>
                    <li className="text-light_secondary text-sm">
                      Minimum Amount : {item?.minimumAmount}
                    </li>
                    <li className="text-light_secondary text-sm">
                      Valid on {formateDate(item?.endTime)} order only
                    </li>
                  </ul>
                </div>
                <div className="w-full flex flex-col justify-between">
                  <div className=" flex items-center">
                    <button
                      disabled={checkEndDateOfCoupon(item)}
                      onClick={() => couponApplyHandle(item)}
                      className={`flex items-center ${
                        checkEndDateOfCoupon(item)
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      <div className="material-symbols-outlined">
                        <img
                          src={ARROW_FORWARD_BLACK_IC}
                          alt=""
                          className="w-5"
                        />
                      </div>
                      <span className="ms-2">Apply</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      </MODEL>

      {/* ============================== HOME TR-ON ADDRESS ====================== */}

      <MODEL
        showModel={selectHomeTryAddressToggle}
        setShowModel={setSelectHomeTryAddressToggle}
        title="Select Address"
      >
        <div className="w-full flex items-center justify-center">
          {customerProfileData?.customer?.addresses?.length > 0 ? (
            <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto p-10 grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-x-4 gap-y-4 hide_scrollbar items-center">
              {customerProfileData?.customer?.addresses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => homeTryAddressSelectHandle(item)}
                    className={` ${
                      item?._id === selectedHomeTryAddress?._id
                        ? "border border-primary"
                        : ""
                    } bg-gray overflow-hidden p-4 cursor-pointer select-none w-full h-44 justify-between flex flex-col`}
                  >
                    <div className="w-full flex justify-between items-center h-12 overflow-hidden">
                      <span className="text-lg font-bold h-full text-ellipsis">
                        {item?.firstname} {item?.label && ` (${item?.label})`}
                      </span>
                    </div>
                    <span className="text-base mt-2 w-full text-wrap truncate h-12 overflow-hidden text-ellipsis">
                      {item?.address1}, {item?.address2}
                    </span>
                    <div className="flex items-center mt-2">
                      <div className="material-symbols-outlined text-xl">
                        <img src={CALL_IC} alt="" className="w-5" />
                      </div>
                      <span className="ms-1">{item?.phone}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-[30vh] w-full flex flex-col items-center text-center text-light_secondary justify-center">
              No Address Found
            </div>
          )}
        </div>
      </MODEL>
    </div>
  );
};

export default ShoppingBag;

export function OrderSummaryProductCard({ product, isCart }) {
  const totalWithLens = product?.lensDetails?.reduce(
    (acc, lens) => acc + lens?.price,
    0
  );
  const [selectedShowPrescription, setSelectedShowPrescription] =
    useState(null);

  // ===================== REMOVE FROM BAG HANDLE ==================

  const [removeFromHomeTryOnHandle] =
    useRemoveFromCustomerHomeTryOnDataMutation();

  const removeFromHomeTryOnHandleClick = () => {
    removeFromHomeTryOnHandle({
      body: {
        product_id: product?._id,
      },
      user: localStorage.getItem("user"),
    }).then((res) => {
      if (res?.data) {
        toast.success(`${product?.name} removed from bag successfully.`);
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  };

  return (
    <div
      className={`${
        isCart ? "bg-gray" : "bg-white"
      } w-full flex flex-col p-4 mt-[25px]`}
    >
      <div className="flex w-full">
        <div
          className={` ${
            isCart ? "bg-white" : "bg-gray"
          } lg:min-w-[140px] lg:max-w-[140px] lg:min-h-[120px] lg:max-h-[120px]
    emd:min-w-[140px] emd:max-w-[140px] emd:min-h-[120px] emd:max-h-[120px]
    small:min-w-[120px] small:max-w-[120px] small:min-h-[100px] small:max-h-[100px]
     object-contain flex items-center justify-center overflow-hidden`}
        >
          <img
            src={
              product?.images?.length > 0
                ? product?.images[0].replace(
                    "upload/",
                    "upload/if_w_gt_150/w_150/if_end/"
                  )
                : ""
            }
            className="w-[120px]"
            alt=""
          />
        </div>
        <div className="h-full justify-between lg:w-[calc(100%-140px)] emd:w-[calc(100%-140px)] small:w-[calc(100%-120px)] pl-4 flex flex-col">
          <div className="w-full flex lg:flex-row emd:flex-row mxs:flex-row lm:flex-row small:flex-col-reverse small:items-end emd:items-start lg:items-start justify-between">
            <span className="mont-regular lg:text-lg emd:text-lg small:text-base">
              {product?.name}
            </span>
          </div>
          <span className="text-base mont-bold">
            <span className="currency_symbol">₹</span>
            {product?.prices?.price}
          </span>
          <div className="w-full flex justify-end">
            <img
              src={DELETE_IC}
              alt=""
              className="w-5 cursor-pointer"
              onClick={() => removeFromHomeTryOnHandleClick()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
