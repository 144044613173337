import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { removeFromMyBag } from "../../redux/slices/myBagSlice";
// import { useDispatch } from "react-redux";
// import { useRemoveFromCustomerBagMutation } from "../../redux/slices/homeSlice";
// import useGetUser from "../../hooks/useGetUser";
import { toast } from "react-toastify";
import useGetUser from "../../../hooks/useGetUser";
import {
  useAddToCustomerBagMutation,
  useRemoveFromCustomerBagMutation,
} from "../../../redux/slices/homeSlice";
import {
  addToMyBag,
  removeFromMyBag,
  setMyBag,
} from "../../../redux/slices/myBagSlice";
import { useNavigate } from "react-router-dom";
import { myBagToggle } from "../../../redux/slices/myBagToggleSlice";
import {
  CLOSE_BLACK_IC,
  CLOSE_IC,
  CLOSE_IC_18,
  DELETE_IC,
} from "../../../assets";

import MODEL from "../../Master/MODEL";
import BTN from "../../Master/BTN";

const MyBagCard = ({ data, setLensStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useGetUser();
  const myBagItems = useSelector((state) => state.myBagData.items);

  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  // ===================== REMOVE FROM BAG HANDLE ==================

  const [removeFromCustomerBag] = useRemoveFromCustomerBagMutation();

  const removeToMyBagHandel = (isAllDelete) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: data?._id,
          decrement: data?.quantity > 1 && !isAllDelete ? true : false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} removed from bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(removeFromMyBag({ id: data?._id, isAllDelete }));
    }
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag({ ...data, quantity: 1 }));
      toast.success(`${data?.name} added to bag successfully.`);
    }
  };

  // ====================== REMOVE LENS HANDLE ================================

  // const removeLensFromMyBag = ({ product_id, prescription_id, lens_id }) => {
  //   if (loggedIn) {
  //     addToCustomerBag({
  //       body: {
  //         products: [
  //           {
  //             product_id: product_id,
  //             quantity: 0,
  //             variant: [],
  //             lens_id:  lens_id,
  //             prescription_id: prescription_id,
  //           },
  //         ],
  //       },
  //       user: localStorage.getItem("user"),
  //     }).then((res) => {
  //       if (res?.data) {
  //         toast.success(`Lens Removed`);
  //       } else {
  //         toast.error(`${res?.error?.data?.message}`);
  //       }
  //     });
  //   } else {
  //     let newBagDataWithRemoveLens = myBagItems.map((item) => {
  //       if (item.product_id === product_id) {
  //         return {
  //           ...item,
  //           lensDetails: null,
  //         };
  //       }
  //       return item;
  //     });
  //     dispatch(setMyBag(newBagDataWithRemoveLens));

  //     toast.success(`Lens Removed`);
  //   }
  // };

  // =================== ADD CART HANDLE =================

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: data?._id },
      });
      setLensStep(1);
      dispatch(myBagToggle());
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  // ====================== REMOVE LENS HANDLE ================================

  const removeLensFromMyBag = (productId, lensId, prescriptionId) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: productId,
          lens_id: lensId,
          prescription_id: prescriptionId,
          decrement: false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens Removed`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    }
  };

  return (
    <div className="w-full flex flex-col mb-6">
      <div className="w-full flex flex-col border-b border-secondary py-2">
        <div className="my_bag_card_main pb-2">
          <div className="img_div">
            <img
              src={data?.image?.length > 0 ? data?.image[0] : ""}
              alt=""
              className="my_bag_product_img"
            />
          </div>
          <div className="my_bag_card_details">
            <div className="my_bg_card_details_inner w-full">
              <div className="flex w-full justify-between">
                <span>{data?.name}</span>
                <img
                  src={DELETE_IC}
                  alt=""
                  className="w-6 cursor-pointer"
                  onClick={() => removeToMyBagHandel(true)}
                />
              </div>
              <span className="price_text">
                <span className="currency_symbol">₹</span>
                {data?.prices?.price * data?.quantity}
              </span>
              {/* <span className="quantity_text">Quantity {data?.quantity}</span> */}
              <div className="flex items-center justify-between mt-2">
                <button
                  onClick={() => removeToMyBagHandel(false)}
                  className={` 
               w-5 h-5 border  text-center text-sm`}
                  // disabled={data?.quantity === 1} ${data?.quantity === 1? "border-light_secondary text-light_secondary": "border-black"}
                >
                  -
                </button>
                <div className="mx-2 text-base">{data?.quantity}</div>
                <button
                  onClick={() =>  {
                data?.isAccessories === true
                  ? addCartHandle(false)
                  : setLensAddModelToggle(true);
              }}
                  className="w-5 h-5 border border-black text-center text-sm"
                >
                  +
                </button>
              </div>
            </div>
            {/* <div className="w-full flex flex-wrap items-center justify-between">
              <button
                onClick={() => {
                  navigate(user?.customer ? "/lens" : "/login", {
                    state: { id: data?._id },
                  });
                  setLensStep(1);
                  dispatch(myBagToggle());
                  document.body.style.overflow = "auto";
                  document.body.style.height = "auto";
                }}
                className="border border-secondary text-secondary py-1 px-2 font-bold text-nowrap lg:text-base emd:text-base small:text-[12px] lg:mt-0 emd:mt-0 small:mt-1"
              >
                Add Lens
              </button>
            </div> */}
          </div>
        </div>
        {data?.lensDetails?.length > 0 && (
          <div className="w-full flex flex-col">
            <span className="mx-16 text-2xl">+</span>
            <div className=" w-full flex flex-col">
              <div className="py-1 text-base uppercase">Lens Details :</div>
              {data?.lensDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center pb-2 pr-5"
                  >
                    <div className="flex items-center">
                      <img
                        src={item.image}
                        alt=""
                        className="min-w-[50px] max-w-[50px] bg-gray"
                      />
                      <span className="text-base ms-3">
                        {item.name + " Lens"} &nbsp;
                        <span className="text-nowrap">(₹ {item.price})</span>
                      </span>
                    </div>
                    <img
                      src={CLOSE_IC}
                      alt=""
                      className="w-5 ms-5 cursor-pointer"
                      onClick={() =>
                        removeLensFromMyBag(
                          data?._id,
                          item?._id,
                          item?.prescriptionDetail?._id
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
            {/* {<span className="text-sm text-red-400 mt-1">error msg</span>} */}
          </div>
        )}
      </div>

      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setLensAddModelToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <BTN
              onP={() => addCartHandle(true)}
              title="Add Lens"
              className={"w-full mont-bold text-lg py-1 px-3"}
            />
            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span className="text-primary cursor-pointer" onClick={() => addCartHandle(false)}>
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default MyBagCard;
