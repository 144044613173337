import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useGetUser from "../../../hooks/useGetUser";
import {
  useAddToCustomerBagMutation,
  useRemoveFromCustomerBagMutation,
} from "../../../redux/slices/homeSlice";
import {
  addToMyBag,
  removeFromMyBag,
  setMyBag,
} from "../../../redux/slices/myBagSlice";
import { useNavigate } from "react-router-dom";
import { myBagToggle } from "../../../redux/slices/myBagToggleSlice";
import {
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_UP,
  CLOSE_BLACK_IC,
  CLOSE_IC,
  CLOSE_IC_18,
  DELETE_IC,
  DOWN_ARROW_IC,
} from "../../../assets";

import MODEL from "../../Master/MODEL";
import BTN from "../../Master/BTN";

const MyBagCardNew = ({ data, setLensStep, blueCutLensIdDataLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useGetUser();
  const myBagItems = useSelector((state) => state.myBagData.items);

  const [openLensDetails, setOpenLensDetails] = useState(null);
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);
  const [lensAddBtnModelToggle, setLensAddBtnModelToggle] = useState(false);
  const [selectedShowPrescription, setSelectedShowPrescription] =
    useState(null);

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  // ===================== REMOVE FROM BAG HANDLE ==================

  const [removeFromCustomerBag] = useRemoveFromCustomerBagMutation();

  const removeToMyBagHandel = (isAllDelete) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: data?._id,
          decrement: data?.quantity > 1 && !isAllDelete ? true : false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} removed from bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(removeFromMyBag({ id: data?._id, isAllDelete }));
    }
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: lensAddBtnModelToggle ? 0 : 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${data?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag({ ...data, quantity: 1 }));
      toast.success(`${data?.name} added to bag successfully.`);
    }
  };

  // =================== ADD CART HANDLE =================

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: {
          id: data?._id,
          isOnlyLens: lensAddBtnModelToggle ? true : false,
        },
      });
      setLensStep(1);
      dispatch(myBagToggle());
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
    setLensAddBtnModelToggle(false);
  };

  // ====================== REMOVE LENS HANDLE ================================

  const removeLensFromMyBag = (productId, lensId, prescriptionId) => {
    if (loggedIn) {
      removeFromCustomerBag({
        body: {
          product_id: productId,
          lens_id: lensId,
          prescription_id: prescriptionId,
          decrement: false,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens Removed`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    }
  };

  // ====================== ADD ZERO POWER LENS HANDLE ================================

  const addZeroPowerLensHandle = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: data?._id,
              quantity: lensAddBtnModelToggle ? 0 : 1,
              variant: [],
              zeroPower: true,
              lens: [
                {
                  lens_id: blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                  addons: [],
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: {
          forZeroPowerLensId: data?._id,
          isOnlyLens: lensAddBtnModelToggle ? true : false,
        },
      });
      dispatch(myBagToggle());
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
    setLensAddModelToggle(false);
    setLensAddBtnModelToggle(false);
  };

  const totalWithLens = data?.lensDetails?.reduce(
    (acc, lens) => acc + lens?.price,
    0
  );

  const totalWithAddons = data?.lensDetails?.reduce((lensSum, lens) => {
    const addonsPrice = lens?.addons?.reduce((addonsSum, addon) => {
      return addonsSum + addon?.price;
    }, 0);
    return lensSum + addonsPrice;
  }, 0);

  const goToProductHandle = () => {
    navigate(`/products/${data?._id}`);
    dispatch(myBagToggle());
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
  };

  const totalAddonsCount = data?.lensDetails?.reduce((lensTotal, lens) => {
    return lensTotal + lens?.addons?.length;
  }, 0);

  return (
    <div className="w-full flex flex-col bg-gray p-4 mb-5">
      <div className="flex w-full mb-3">
        <div
          onClick={() => goToProductHandle()}
          className="lg:min-w-[140px] lg:max-w-[140px] lg:min-h-[120px] lg:max-h-[120px]
        emd:min-w-[140px] emd:max-w-[140px] emd:min-h-[120px] emd:max-h-[120px]
        small:min-w-[120px] small:max-w-[120px] small:min-h-[100px] small:max-h-[100px]
         object-contain flex items-center justify-center overflow-hidden bg-white"
        >
          <img
            src={
              data?.image?.length > 0
                ? data?.image[0].replace(
                    "upload/",
                    "upload/if_w_gt_150/w_150/if_end/"
                  )
                : ""
            }
            className="w-[120px]"
            alt=""
          />
        </div>
        <div className="lg:w-[calc(100%-140px)] emd:w-[calc(100%-140px)] small:w-[calc(100%-120px)] pl-4 flex flex-col">
          <div className="w-full flex lg:flex-row emd:flex-row mxs:flex-row lm:flex-row small:flex-col-reverse small:items-end emd:items-start lg:items-start justify-between">
            <span
              onClick={() => goToProductHandle()}
              className="mont-regular lg:text-lg emd:text-lg small:text-base cursor-pointer"
            >
              {data?.name}
            </span>
            <img
              src={DELETE_IC}
              alt=""
              className="w-6 cursor-pointer"
              onClick={() => removeToMyBagHandel(true)}
            />
          </div>
          <span className="text-base mont-regular">
            <span className="currency_symbol">₹</span>
            {data?.prices?.price}
          </span>
          <div className="w-fit flex items-center justify-between mt-2">
            <button
              onClick={() => removeToMyBagHandel(false)}
              className={` 
               w-5 h-5 border  text-center text-sm`}
            >
              -
            </button>
            <div className="mx-2 text-sm">{data?.quantity}</div>
            <button
              onClick={() => {
                data?.isAccessories === true
                  ? addCartHandle(false)
                  : setLensAddModelToggle(true);
              }}
              className="w-5 h-5 border border-black text-center text-sm"
            >
              +
            </button>
          </div>
          {data?.isAccessories !== true ? (
            <div>
              {!data?.lensDetails ? (
                <button
                  onClick={() => {
                    navigate(user?.customer ? "/lens" : "/login", {
                      state: { id: data?._id, isOnlyLens: true },
                    });
                    setLensStep(1);
                    dispatch(myBagToggle());
                    document.body.style.overflow = "auto";
                    document.body.style.height = "auto";
                  }}
                  className="w-fit border border-secondary text-secondary py-1 px-2 font-bold text-nowrap text-xs mt-2"
                >
                  Add Lens
                </button>
              ) : data?.quantity > data?.lensDetails?.length &&
                data?.quantity !== data?.lensDetails?.length ? (
                <button
                  // onClick={() => {
                  //   navigate(user?.customer ? "/lens" : "/login", {
                  //     state: { id: data?._id, isOnlyLens: true },
                  //   });
                  //   setLensStep(1);
                  //   dispatch(myBagToggle());
                  //   document.body.style.overflow = "auto";
                  //   document.body.style.height = "auto";
                  // }}
                  onClick={() => setLensAddBtnModelToggle(true)}
                  className="w-fit border border-secondary text-secondary py-1 px-2 font-bold text-nowrap text-xs mt-2"
                >
                  Add Lens
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {data?.lensDetails?.length > 0 && (
        <div className="w-full flex flex-col">
          <div className=" w-full flex flex-col">
            <div
              onClick={() =>
                setOpenLensDetails(
                  data?.product_id !== openLensDetails ? data?.product_id : null
                )
              }
              className="cursor-pointer w-full flex items-center justify-between select-none"
            >
              <div className="text-base mont-regular uppercase">
                Lens Details{" "}
                <span className="text-sm mont-bold">
                  <span className="mont-regular">( </span>
                  {data?.lensDetails?.length}{" "}
                  <span className="mont-regular"> Lens</span>
                  <span className="mont-regular">
                    {totalAddonsCount > 0 && " + "}
                  </span>
                </span>
                {totalAddonsCount > 0 ? (
                  <span className="text-sm mont-bold text-primary">
                    {totalAddonsCount}
                    <span className="mont-regular text-primary"> Addons</span>
                  </span>
                ) : (
                  ""
                )}
                <span className="mont-regular"> )</span>
              </div>
              <img
                src={
                  data?.product_id === openLensDetails
                    ? ARROW_KEYWORD_UP
                    : ARROW_KEYWORD_DOWN
                }
                alt=""
                className=""
              />
            </div>

            {data?.product_id === openLensDetails &&
              data?.lensDetails?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex items-start justify-between mt-2 pb-2"
                  >
                    <div
                      onClick={() =>
                        setSelectedShowPrescription(item?.prescriptionDetail)
                      }
                      className="flex items-start"
                    >
                      <span>{index + 1}.</span>
                      <div className="text-base ms-2 cursor-pointer flex flex-col">
                        <span className="flex text-base">
                          {item.name + " Lens"} &nbsp;
                          <span className="text-nowrap">(₹ {item.price})</span>
                        </span>
                        {item?.addons?.map((addon, addonIndex) => {
                          return (
                            <span
                              key={addonIndex}
                              className="ms-2 text-light_secondary text-sm"
                            >
                              <span className="me-2 text-light_secondary">
                                {addonIndex + 1}.
                              </span>
                              {addon?.name} &nbsp;
                              <span className="text-nowrap text-light_secondary">
                                (₹ {addon.price})
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                    <img
                      src={CLOSE_IC}
                      alt=""
                      className="w-6 ms-5 cursor-pointer"
                      onClick={() =>
                        removeLensFromMyBag(
                          data?._id,
                          item?._id,
                          item?.prescriptionDetail?._id
                        )
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div className="w-full flex justify-between mt-2 pt-2 border-t border-dashed border-light_secondary">
        <span>
          Total Price{" "}
          {data?.lensDetails?.length > 0
            ? `(Frame + Lens${totalAddonsCount > 0 ? ` + Addons` : ``})`
            : ``}
        </span>
        <span className="text-base mont-regular">
          <span className="currency_symbol">₹</span>
          {data?.lensDetails?.length > 0
            ? data?.prices?.price * data?.quantity +
              totalWithLens +
              totalWithAddons
            : data?.prices?.price * data?.quantity}
        </span>
      </div>

      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={() => {
          setLensAddModelToggle(false);
          setLensAddBtnModelToggle(false);
        }}
        showModel={lensAddModelToggle || lensAddBtnModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setLensAddModelToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <BTN
              onP={() => addCartHandle(true)}
              title="Add Lens With Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 lg:text-base emd:text-sm small:text-sm"
              }
            />
            <BTN
              bordered
              onP={() => addZeroPowerLensHandle()}
              title="Add Lens With Zero Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 mt-5 lg:text-base emd:text-sm small:text-sm"
              }
            />
            {!lensAddBtnModelToggle && (
              <div className="w-full flex items-center justify-between py-5">
                <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
                <span>OR</span>
                <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              </div>
            )}
            {!lensAddBtnModelToggle && (
              <span
                className="text-primary cursor-pointer text-center lg:text-base emd:text-sm small:text-sm"
                onClick={() => addCartHandle(false)}
              >
                Buy Only Frame Without Lens
              </span>
            )}
          </div>
        </div>
      </MODEL>

      {/* =================== PRESCRIPTION DETAILS MODEL =============== */}
      <MODEL
        setShowModel={() => setSelectedShowPrescription(null)}
        showModel={selectedShowPrescription !== null}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Prescription details</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setSelectedShowPrescription(null)}
            />
          </div>
          <div className="w-full my-5 px-5">
            <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Left Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.leftEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.leftEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {selectedShowPrescription?.leftEye?.left_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.leftEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.leftEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                <div className="my-2 text-center font-bold underline underline-offset-4">
                  Right Eye
                </div>
                <div className="w-full flex items-center justify-between mt-3">
                  <span className="text-sm font-bold">Sphere :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.sphere || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Cylinder :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.cylinder || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">Axis :</span>
                  <span className="text-sm">
                    {selectedShowPrescription?.rightEye?.axis || 0}
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-sm font-bold">PD :</span>
                  <div className="flex items-center">
                    <span className="text-sm">
                      {selectedShowPrescription?.rightEye?.pd || 0}
                    </span>
                    {/* <span className="text-sm ms-2">
                          {item?.leftEye?.right_pd}
                        </span> */}
                  </div>
                </div>
                {selectedShowPrescription?.rightEye?.add && (
                  <div className="w-full flex items-center justify-between">
                    <span className="text-sm font-bold">ADD :</span>
                    <div className="flex items-center">
                      <span className="text-sm">
                        {selectedShowPrescription?.rightEye?.add || 0}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default MyBagCardNew;
