import "swiper/css";
import "./browseglasses.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";

import { Breakpoint } from "react-socks";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function BrowseGlasses({ homeData }) {
  const navigate = useNavigate();

  return (
    <div className="BrowseGlasses">
      <div className="font-sabon text-color heading lg:mb-5 emd:mb-5 small:mb-5">
        {homeData?.curated_glasses?.title}
      </div>
      <div className="d-flex g-10"></div>
      <Breakpoint large up>
        <div className="slider ">
          {homeData?.curated_glasses?.banners?.map((item, index) => {
            return (
              <div
                key={index}
                className="slider-item cursor-pointer"
                style={{ position: "relative" }}
                onClick={() => navigate(item?.redirect)}
              >
                <img
                  className="img glass-img "
                  src={item?.image}
                  alt="slider  1"
                  style={{
                    // position: "absolute",
                    zIndex: "2",
                    objectFit: "contain",
                    paddingBottom: "28%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                />
                {/* <img className="img" src={item?.box_img} alt="slider  1" /> */}
                <img
                  className="img-back"
                  src={item?.background}
                  alt="slider  1"
                  style={{ objectFit: "contain" }}
                />
                <Link
                  to={item?.redirect}
                  className="text mont-regular text-nowrap"
                >
                  {item?.button_text}
                </Link>
                <div className="overlay"></div>
              </div>
            );
          })}
        </div>
      </Breakpoint>

      <Breakpoint medium down>
        <div className="slider">
          <Swiper
            slidesPerView={1.2}
            spaceBetween={20}
            centeredSlides={true}
            initialSlide={1}
            pagination={{
              clickable: false,
            }}
            modules={[]}
            className="mySwiper"
          >
            {homeData?.curated_glasses?.banners?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    onClick={() => navigate(item?.redirect)}
                    className="slider-item cursor-pointer"
                    style={{ position: "relative", width: "100%" }}
                  >
                    <img
                      className="img glass-img"
                      src={item?.image}
                      alt="slider  1"
                      style={{
                        position: "absolute",
                        zIndex: "2",
                        objectFit: "contain",
                        padding: "10%",
                        paddingBottom: "28%",
                      }}
                    />
                    {/* <img className="img" src={item?.box_img} alt="slider  1" /> */}
                    <img
                      className="img-back object-cover"
                      src={item?.background}
                      alt="sliderimage1"
                    />
                    <div className="overlay"></div>
                    <Link
                      to={item?.redirect}
                      className="text mont-regular text-nowrap"
                    >
                      {item?.button_text}
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Breakpoint>
    </div>
  );
}

export default BrowseGlasses;
