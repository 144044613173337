import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddToCustomerBagMutation,
  useAddToCustomerHomeTryOnDataMutation,
  useAddToWishlistMutation,
  useGetProductsMutationMutation,
  useRemoveFromWishlistMutation,
} from "../../../redux/slices/homeSlice";
import { Bounce, toast } from "react-toastify";
import "./Category.scss";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../hooks/useGetUser";
import { addToMyBag } from "../../../redux/slices/myBagSlice";
import LOADER from "../../Master/LOADER";
import { FilterSidePanel } from "./FilterSidePanel";
import Skeleton from "react-loading-skeleton";
import { fakeData } from "../../../json";
import LazyImageLoader from "../../Master/LazyImageLoader";
import MODEL from "../../Master/MODEL";
import { ADD_CART_IC, BAG_IC, CLOSE_BLACK_IC } from "../../../assets";
import BTN from "../../Master/BTN";
import INPUT from "../../Master/INPUT";
import RatingCard from "../../Master/RatingCard";
import { useFormik } from "formik";
function Category({ blueCutLensIdDataLabel }) {
  const params = useParams();
  const location = useLocation();
  const category = params?.name;
  const [showFilter, setShowFilter] = useState(false);
  const showFilterHandler = () => {
    setShowFilter(!showFilter);
  };
  const [sortBy, setSortBy] = useState({});
  const [totalDocs, setTotalDocs] = useState(0);
  const [attributeFilters, setAttributeFilters] = useState(
    location?.state?.shape
      ? {
          Shape:
            location?.state?.shape?.charAt(0)?.toUpperCase() +
            location?.state?.shape?.slice(1)?.toLowerCase(),
        }
      : {}
  );
  const [categoryTitle, setCategoryTitle] = useState(null);
  return (
    <div className="categories">
      {!location?.pathname?.includes("search") && (
        <div className="best-selling-frames-banner">
          <LazyImageLoader
            imgSrc={"/best-selling-frame.jpeg"}
            imgAlt="Best Selling Frames"
            imgWidth="100%"
            imgObjectFit={"cover"}
          />
          <h1 className="overlay font-sabon">{categoryTitle}</h1>
        </div>
      )}
      <div className="d-flex j-sb filter-nav">
        <div className=""></div>
        <div className="sort-filter-btn" onClick={showFilterHandler}>
          SORT AND FILTER
        </div>
      </div>
      {showFilter && (
        <FilterSidePanel
          showFilter={showFilter}
          showFilterHandler={showFilterHandler}
          category={category}
          setSortBy={setSortBy}
          totalDocs={totalDocs}
          setAttributeFilters={setAttributeFilters}
          blueCutLensIdDataLabel={blueCutLensIdDataLabel}
        />
      )}
      <div className="category-grid">
        <VirtualProducts
          category={category}
          sortBy={sortBy}
          setTotalDocs={setTotalDocs}
          attributeFilters={attributeFilters}
          blueCutLensIdDataLabel={blueCutLensIdDataLabel}
          setCategoryTitle={setCategoryTitle}
        />
      </div>
    </div>
  );
}

export const ProductCard = (props) => {
  const user = useGetUser();
  const { pathname } = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);
  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (props?.product._id) {
        const found = wishlistData.find(
          (element) => element._id === props.product._id
        );
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, props]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!props.product._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: props.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: props.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };
  const navigate = useNavigate();
  const openInNewTab = (redirectUrl) => {
    window.open(redirectUrl, "_blank", "noopener,noreferrer");
  };
  const location = useLocation();
  const dispatch = useDispatch();

  const stock = 10;

  const redirctUrl = `/products/${props?.product?._id}`;
  var image = null;

  image = props.product?.image[0] ? props.product?.image[0] : null;
  if (image) {
    var transformedImage = image.replace(
      "upload/",
      "upload/if_w_gt_400/w_400/if_end/"
    );
  }
  //on click on sku copy the sku to clipboard
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.product.sku);
    e.target.innerText = "Copied!";
    setTimeout(() => {
      e.target.innerText = "SKU : " + props.product.sku;
    }, 1000);
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: props?.product?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${props?.product?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(props?.product));
      toast.success(`${props?.product?.name} added to bag successfully.`);
    }
  };

  // ====================== New arrival ================================
  const [showNewArrival, setShowNewArrival] = useState(false);
  const interval = 1000 * 60 * 60 * 24 * 30; // 30 days
  useEffect(() => {
    if (props?.product?.createdAt) {
      const productDate = new Date(props?.product?.createdAt);
      const currentDate = new Date();
      const diff = currentDate - productDate;
      if (diff < interval) {
        setShowNewArrival(true);
      }
    }
  }, [props?.product?.createdAt]);

  // =================== ADD CART HANDLE =================
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: props?.product?._id },
      });
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  // ====================== ADD ZERO POWER LENS HANDLE ================================

  const addZeroPowerLensHandle = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: props?.product?._id,
              quantity: 1,
              variant: [],
              zeroPower: true,
              lens: [
                {
                  lens_id:
                    props?.blueCutLensIdDataLabel?.labels?.zeroPowerLensId,
                  addons: [],
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      navigate("/login", {
        state: {
          forZeroPowerLensId: props?.product?._id,
          isOnlyLens: false,
        },
      });
    }
    setLensAddModelToggle(false);
  };

  const calculateDiscountPercentage = (originalPrice, discount) => {
    return Math.round(((discount / originalPrice) * 100).toFixed(2)); // 2 decimal places
  };

  // ================== VERIFY PIN CODE AND ADD TO HOME TRYON =====================

  const [pinCodeVerifyId, setPinCodeVerifyId] = useState("");
  const [addToCustomerHomeTryOnData] = useAddToCustomerHomeTryOnDataMutation();

  const addToHomeTryOnHandel = (productId) => {
    console.log("productId", productId);
    addToCustomerHomeTryOnData({
      body: {
        products: [
          {
            product_id: productId,
            variant: [],
          },
        ],
      },
      user: localStorage.getItem("user"),
    }).then((res) => {
      if (res?.data) {
        toast.success(`added to home try-on successfully.`);
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  };

  const pincodeFormik = useFormik({
    initialValues: {
      pincode: "",
    },
    onSubmit: async function (values) {
      console.log("Submitting form...");

      const myHeaders = new Headers();
      myHeaders.append("x-api-key", process.env.REACT_APP_API_KEY);
      myHeaders.append("Authorization", localStorage.getItem("user"));

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}netre/checkHtoAvailability/${values.pincode}`,
          requestOptions
        );
        const result = await response.json();

        if (result?.isServiceable === true) {
          localStorage.setItem("isServiceable", true);
          addToHomeTryOnHandel(pinCodeVerifyId);
          setPinCodeVerifyId("");
          pincodeFormik.resetForm();
        } else {
          toast.error("Currently Service is not available in your city!");
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values?.pincode) {
        errors.pincode = "Pin code is Required!";
      } else if (values?.pincode && values?.pincode?.length !== 6) {
        errors.pincode = "Pincode must be 6 digits!";
      }
      return errors;
    },
  });

  return (
    <div
      className="card hover:shadow-md lg:p-5 emd:p-5 small:p-2 border-[0.5px] border-light_secondary "
      style={{
        outline:
          props?.product?._id &&
          props?.newFrame?._id &&
          props?.product?._id === props?.newFrame?._id
            ? "1px solid var(--color2)"
            : "unset",
      }}
    >
      <div className="top relative">
        <div
          style={{ visibility: showNewArrival ? "visible" : "hidden" }}
          className="new-arrival-label mont-bold absolute lg:-top-1.5 lg:-left-7 emd:-top-1.5 emd:-left-7 small:-top-0 small:-left-3 bg-primary  text-white px-4 py-1"
        >
          NEW ARRIVAL
        </div>
        {calculateDiscountPercentage(
          props?.product?.prices?.originalPrice,
          Math.round(props?.product?.prices?.discount)
        ) !== 0 &&
          !showNewArrival && (
            <div className="offer_ribbon leading-[1.1em] py-1.5 flex flex-col lg:px-2 emd:px-2 small:px-1 lg:text-[12px] emd:text-[10px] small:text-[8px] lg:-top-[1.6rem] lg:-left-5 emd:-top-6 emd:-left-5 small:-top-3 small:-left-2 bg-primary mont-bold text-white text-center">
              {calculateDiscountPercentage(
                props?.product?.prices?.originalPrice,
                Math.round(Math.round(props?.product?.prices?.discount))
              )}
              %
              <span className="lg:text-[10px] emd:text-[8px] small:text-[6px] mont-bold text-white">
                Off
              </span>
            </div>
          )}

        {wishListed ? (
          <svg
            onClick={handleWishlist}
            className="pointer filled min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]"
            viewBox="0 -960 960 960"
            fill="#5f6368"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="min-w-fit max-w-fit min-h-fit max-h-fit"
              d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
              transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
            />
          </svg>
        ) : (
          <svg
            onClick={handleWishlist}
            className="pointer min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path
              className="min-w-fit max-w-fit min-h-fit max-h-fit"
              d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z"
            />
          </svg>
        )}

        {/* <img
          src={BAG_IC}
          alt=""
          className="min-w-6 max-w-6 object-contain lg:ms-1 emd:ms-1 small:ms-0 cursor-pointer"
          onClick={() => {
            props?.product?.isAccessories === true
              ? addCartHandle(false)
              : setLensAddModelToggle(true);
          }}
        /> */}

        {props.product?.quantity > 0 && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "In Store"
                ? "count"
                : "count hide"
            }
          >
            {props.product?.quantity}
          </div>
        )}
      </div>
      <div
        className="middle pointer relative"
        key={props?.product?._id}
        onClick={() =>
          !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
        }
      >
        <LazyImageLoader
          imgSrc={transformedImage ? transformedImage : "/Netre-logo.svg"}
          className="product-image"
          imgAlt="product image"
          imgObjectFit={transformedImage ? "contain" : "contain"}
        />
        <div className="absolute right-0 bottom-2">
          {props?.product?.totalReviews > 0 && (
            <RatingCard
              rating={props?.product?.averageRating}
              totalRating={props?.product?.totalReviews}
              mainClassName={"px-2 py-0.5"}
            />
          )}
        </div>
      </div>
      <div className="bottom relative lg:gap-[5px] emd:gap-[5px] small:gap-0">
        {props?.product?.name && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "Virtual"
                ? "title qty-present"
                : "title"
            }
          >
            {props.product?.name}
          </div>
        )}
        {/* <div
          className={
            location.pathname === "/inventory" ? "sku show qty-present" : "sku"
          }
          onClick={copyToClipboard}
        >
          SKU : {props?.product?.sku}
        </div> */}
        {Math.round(props?.product?.prices?.discount) !== 0 ? (
          <div className="flex items-center">
            <div className="line-through text-light_secondary text-nowrap">
              {props?.product?.prices?.originalPrice}
            </div>
            <div className="ms-2 text-nowrap mont-bold">
              ₹ {props?.product?.prices?.price}
            </div>
          </div>
        ) : (
          <div className="mont-bold lg:text-base emd:text-base small:text-xs">
            ₹ {props?.product?.prices?.price}
          </div>
        )}

        {location.pathname.includes("exchange-order") ? (
          <span
            className="add_to_bag_text"
            onClick={() => {
              props.setNewFrame(null);
              props.setNewFrame(props?.product);
            }}
          >
            {props?.product?._id &&
            props?.newFrame?._id &&
            props?.product?._id === props?.newFrame?._id
              ? "Selected For Exchanged"
              : "Exchange Now"}
          </span>
        ) : (
          <div className="w-full">
            {!props?.product?.isAccessories ? (
              <div className="flex items-center exl:flex-row xl:flex-row fix_lg:flex-col emd:flex-col small:flex-col lg:justify-between emd:justify-center small:justify-center">
                <span
                  onClick={() => {
                    props?.product?.isAccessories === true
                      ? addCartHandle(false)
                      : setLensAddModelToggle(true);
                  }}
                  className="cursor-pointer text-nowrap w-full px-2 py-2 lg:text-sm emd:text-sm small:text-[10px] text-center border border-primary text-primary"
                >
                  Add to Bag
                </span>
                <span
                  onClick={() => {
                    if (!loggedIn) {
                      toast.error("Please log in to add into Home Tryon");
                      return;
                    } else if (props?.product?.isAddedInHto) {
                      navigate("/shopping-bag", {
                        state: { isHomeTryOn: true },
                      });
                    } else if (localStorage.getItem("isServiceable")) {
                      addToHomeTryOnHandel(props?.product?._id);
                    } else {
                      setPinCodeVerifyId(props?.product?._id);
                    }
                  }}
                  className="cursor-pointer text-nowrap exl:ms-2 xl:ms-2 fix_lg:ms-0 emd:ms-0 small:ms-0 w-full exl:mt-0 xl:mt-0 fix_lg:mt-1 emd:mt-1 small:mt-1 px-2 py-2 lg:text-sm emd:text-sm small:text-[10px] text-center border border-primary text-primary"
                >
                  {props?.product?.isAddedInHto
                    ? "Go to Home Tryon"
                    : "Add to Home Tryon"}
                </span>
              </div>
            ) : (
              <></>
            )}

            {/* {!props?.product?.isAccessories ? (
              <span
                onClick={() => {
                  if (!loggedIn) {
                    toast.error("Please log in to add into Home Tryon");
                    return;
                  } else if (props?.product?.isAddedInHto) {
                    navigate("/shopping-bag", { state: { isHomeTryOn: true } });
                  } else if (localStorage.getItem("isServiceable")) {
                    addToHomeTryOnHandel(props?.product?._id);
                  } else {
                    setPinCodeVerifyId(props?.product?._id);
                  }
                }}
                className="add_to_bag_text"
              >
                {props?.product?.isAddedInHto
                  ? "Go to Home Tryon"
                  : "Add to Home Tryon"}
              </span>
            ) : (
              <></>
            )} */}
          </div>
        )}
      </div>
      {props.product?.quantity === 0 ||
        props.product?.quantity === "0" ||
        (Number(props.product?.quantity) <= 0 && (
          <div
            className="sold-out"
            onClick={() =>
              !pathname?.includes("exchange-order")
                ? navigate(redirctUrl)
                : null
            }
          >
            <div className="div">SOLD OUT</div>
          </div>
        ))}
      {Number(stock) <= 0 && (
        <div
          className="sold-out"
          onClick={() =>
            !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
          }
        >
          <div className="div">SOLD OUT</div>
        </div>
      )}
      {Number(stock) > 0 &&
        location.pathname === "/inventory" &&
        (props.operation === "Add" ||
          props.operation === "Return" ||
          props.operation === "Exchange") && (
          <div className="quantity">
            <div className="label">Qty</div>
          </div>
        )}

      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setLensAddModelToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <BTN
              onP={() => addCartHandle(true)}
              title="Add Lens With Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 lg:text-base emd:text-sm small:text-sm"
              }
            />
            <BTN
              bordered
              onP={() => addZeroPowerLensHandle()}
              title="Add Lens With Zero Power"
              className={
                "w-full mont-bold text-lg py-1 px-3 mt-5 lg:text-base emd:text-sm small:text-sm"
              }
            />
            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              className="text-primary cursor-pointer text-center lg:text-base emd:text-sm small:text-sm"
              onClick={() => addCartHandle(false)}
            >
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>
      {/* =================== VERIFY PIN-CODE MODEL =============== */}
      <MODEL
        setShowModel={() => setPinCodeVerifyId("")}
        showModel={pinCodeVerifyId !== ""}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Verify PinCode</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setPinCodeVerifyId("")}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <INPUT
              placeHolder={"Enter Your PinCode"}
              name="pincode"
              onChange={pincodeFormik.handleChange}
              val={pincodeFormik.values.pincode}
              onBlur={pincodeFormik.handleBlur}
              errorMsg={pincodeFormik.errors.pincode}
              touched={pincodeFormik.touched.pincode}
            />
            <BTN
              onP={pincodeFormik.handleSubmit}
              type="submit"
              title="Verify"
              className={
                "w-full mont-bold text-lg py-1 px-3 mt-5 lg:text-base emd:text-sm small:text-sm"
              }
            />
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default Category;

export function VirtualProducts(props) {
  const params = useParams();
  const [paramVal, setParamVal] = useState("");
  const [
    getProductsMutation,
    { data, isLoading, isFetching, isSuccess, isError, error },
  ] = useGetProductsMutationMutation();

  const [page, setPage] = useState(1);

  const [allLoaded, setAllLoaded] = useState(false); // To track if all items are loaded
  // Mock API to simulate data fetching
  const fetchData = async (page, limit) => {
    // randomize array of products data
    function shuffleArray(array) {
      const arrayCopy = [...array]; // Create a shallow copy of the array
      for (let i = arrayCopy.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
      }
      return arrayCopy; // Return the shuffled array copy
    }

    // return new Array(limit).fill(null).map((_, i) => `Item ${(page - 1) * limit + i + 1}`);

    const data = await getProductsMutation({
      body: {
        attributes: props.attributeFilters,
      },
      page: page,
      limit: limit,
      queryParams: params?.name
        ? `category=${props.category}${
            props?.sortBy?.value ? `&price=${props?.sortBy?.value}` : ``
          }`
        : params?.searchProductParams === "price=best-selling"
        ? "&price=best-selling"
        : `${
            params?.searchProductParams
              ? `title=${params?.searchProductParams}&price=${props?.sortBy?.value}`
              : ""
          }`,
    }).then((res) => {
      if (res.data) {
        props.setTotalDocs(res.data.totalDoc);
        props.setCategoryTitle(res.data?.banner?.title);
        if (res.data.products.length === 0) {
          setAllLoaded(true);
        }
        return res.data.products;
      }
      if (res.error) {
        console.log(res.error);
      }
    });

    return shuffleArray(data);
  };

  const [items, setItems] = useState([]); // Holds the grid items
  // console.log("total items", items.length);
  const [loading, setLoading] = useState(false); // Loading state
  const limit = 20; // Limit per page
  const observer = useRef(); // To track the 10th item for intersection

  // Load data on page change
  const loadItems = async () => {
    setLoading(true);
    const newItems = await fetchData(page, limit); // Fetch new items based on page
    // setItems((prev) => [...prev, ...newItems]); // Append new items to the list
    // check if  the prev items are present or not only add those which are not present
    setItems((prev) => {
      // Create a Map where the key is the item._id and the value is the item itself
      const map = new Map();

      // Combine prev and newItems arrays
      const combinedItems = [...prev, ...newItems];

      // Iterate over the combined array and set each item in the Map using its _id as the key
      combinedItems.forEach((item) => map.set(item._id, item));

      // Return an array of the values from the Map (which will be unique by _id)
      return Array.from(map.values());
    });
    setLoading(false);
  };
  useEffect(() => {
    if (props?.sortBy?.value || props?.attributeFilters || props?.category) {
      if (!allLoaded) {
        loadItems();
      }
    }
  }, [page]);

  const resetAll = () => {
    if (observer.current) observer.current.disconnect();
    observer.current = null;
    setItems([]);
    setAllLoaded(false);
    setPage(1);
    loadItems();
  };

  useEffect(() => {
    resetAll();
  }, [props?.sortBy?.value, props?.attributeFilters, props?.category]);

  useEffect(() => {
    setParamVal((prev) => prev);
    if (paramVal !== params.searchProductParams) {
      resetAll();
    }
  }, [params?.searchProductParams]);

  // Intersection observer callback
  const lastItemRef = useCallback(
    (node) => {
      if (loading) return; // If loading, do nothing

      // Disconnect previous observer
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // When the 10th item (or last item in the list) is in view, increment page number
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node); // Observe the new node
    },
    [loading]
  );

  return (
    <div>
      <div className="cards">
        {items.map((item, index) => {
          // Attach the ref to the 10th item (or last item) in the grid
          if (index === items.length - 1) {
            return (
              <div ref={lastItemRef} key={index}>
                {/* {item?._id} */}
                <ProductCard
                  key={index}
                  product={item}
                  route={"/virtual-tryon"}
                  storeType={"Virtual"}
                  {...props}
                  blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel}
                />
              </div>
            );
          } else {
            return (
              <div key={index}>
                {/* {item?._id} */}
                <ProductCard
                  key={index}
                  product={item}
                  route={"/virtual-tryon"}
                  storeType={"Virtual"}
                  {...props}
                  blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel}
                />
              </div>
            );
          }
        })}
        {items.length > 3 && (
          <Link to="/" className="card category-banner-1">
            <div className="img-box">
              <LazyImageLoader
                imgSrc={"/best-selling-frame.jpeg"}
                imgAlt="Best Selling Frames"
                imgObjectFit={"cover"}
              />
            </div>
          </Link>
        )}
        {items.length > 16 && (
          <Link to="/" className="card category-banner-2">
            <div className="img-box">
              <LazyImageLoader
                imgSrc={"/eyeglasses-brands.webp"}
                imgAlt="Category Banner"
                imgObjectFit={"cover"}
              />
            </div>
          </Link>
        )}
      </div>
      {!items?.length > 0 && !loading ? (
        <div className="no_product_found">No Product Found</div>
      ) : null}
      {(!isSuccess || loading) && (
        <div className="cards">
          {fakeData?.map((item, index) => {
            return <Skeleton key={index} className="card" />;
          })}
        </div>
      )}
    </div>
  );
}
