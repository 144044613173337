import React, { useEffect, useState } from "react";
import INPUT from "../../Master/INPUT";
import BTN from "../../Master/BTN";
import { CIRCLE_CLOSE_IC, DELETE_IC, PDF_IC, UPLOAD_IC } from "../../../assets";
import MODEL from "../../Master/MODEL";
import {
  useAddPrescriptionMutation,
  useAddToCustomerBagMutation,
  useDeletePrescriptionMutation,
  useGetLensListQuery,
  useGetPrescriptionListQuery,
} from "../../../redux/slices/homeSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMyBag } from "../../../redux/slices/myBagSlice";
import useGetUser from "../../../hooks/useGetUser";

const Lens = ({ step, setStep }) => {
  const userToken = localStorage.getItem("user");

  const user = useGetUser();
  const { pathname } = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myBagItems = useSelector((state) => state.myBagData.items);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [prescriptionUploadType, setPrescriptionUploadType] =
    useState("manual"); // manually, upload
  const [selectedPrescriptionUploadType, setSelectedPrescriptionUploadType] =
    useState(""); // manually, upload

  // const [selectedSingleVision, setSelectedSingleVision] = useState("glasses");
  // const [selectedProgressive, setSelectedProgressive] = useState("bifocals");
  // const [selectedType, setSelectedType] = useState({});
  const [accountPrescriptionToggle, setAccountPrescriptionToggle] =
    useState(false);

  const [selectedLens, setSelectedLens] = useState(null);
  const [selectedMyPrescription, setSelectedMyMyPrescription] = useState(null);
  const [deletePrescriptionId, setDeletePrescriptionId] = useState("");

  const confirmPrescriptionSelectTypeHandle = () => {
    if (selectedMyPrescription && prescriptionUploadType !== "manual") {
      setStep(3);
      // setSelectedType(selectedCategory?.types[0]);
    } else {
      setSelectedPrescriptionUploadType(prescriptionUploadType);
    }
  };

  const confirmPrescriptionUploadTypeHandle = () => {
    setStep(3);
  };
  const {
    data: lensListData,
    isSuccess: lensListDataIsSuccess,
    isLoading: lensListDataIsLoading,
    isError: lensListDataIsError,
    error: lensListDataError,
  } = useGetLensListQuery({ user: userToken });

  const {
    data: prescriptionListData,
    isSuccess: prescriptionListDataIsSuccess,
    isLoading: prescriptionListDataIsLoading,
    isError: prescriptionListDataIsError,
    error: prescriptionListDataError,
  } = useGetPrescriptionListQuery({ user: userToken }, { skip: !userToken });

  // -------------------------------- ADD PRESCRIPTION HANDLE --------------------------------

  const [addPrescription] = useAddPrescriptionMutation();

  const findUpdatedPrescriptionById = (id, data) => {
    const prescription = data?.find((item) => item._id === id);
    return prescription || null;
  };

  const prescriptionFormik = useFormik({
    initialValues: {
      brandName: "",
      lensType: "",
      // rightEye: {
      right_sph: "",
      right_cyl: "",
      right_axis: "",
      right_add: "",
      right_pd: "",
      // },
      // leftEye: {
      left_sph: "",
      left_cyl: "",
      left_axis: "",
      left_add: "",
      left_pd: "",
      // },
    },
    onSubmit: async function (values) {
      addPrescription({
        body: {
          brandName: values?.brandName,
          lensType: "lens",
          rightEye: {
            sphere: values?.right_sph,
            cylinder: values?.right_cyl,
            axis: values?.right_axis,
            pd: values?.right_pd,
            add:
              selectedCategory?.category === "progressive" ||
              selectedCategory?.category === "bifocal"
                ? values?.right_add
                : "",
          },
          leftEye: {
            sphere: values?.left_sph,
            cylinder: values?.left_cyl,
            axis: values?.left_axis,
            pd: values?.left_pd,
            add:
              selectedCategory?.category === "progressive" ||
              selectedCategory?.category === "bifocal"
                ? values?.left_add
                : "",
          },
        },
        user: localStorage.getItem("user"),
        id: selectedMyPrescription?._id ? selectedMyPrescription?._id : null,
      }).then((res) => {
        if (res?.data) {
          setSelectedMyMyPrescription(
            selectedMyPrescription?._id
              ? findUpdatedPrescriptionById(
                  selectedMyPrescription?._id,
                  res?.data?.lensPrescription
                )
              : res?.data?.lensPrescription
          );
          setStep(3);
          // setSelectedType(selectedCategory?.types[0]);
          prescriptionFormik.resetForm();
          toast.success(`Prescription added successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.brandName) {
        errors.brandName = "Name is Required!";
      } else if (!values?.right_sph) {
        errors.right_sph = "SPH is Required!";
      } else if (!values?.right_cyl) {
        errors.right_cyl = "CYL is Required!";
      } else if (!values?.right_axis) {
        errors.right_axis = "Axis is Required!";
      } else if (
        !values?.right_add &&
        (selectedCategory?.category === "progressive" ||
          selectedCategory?.category === "bifocal")
      ) {
        errors.right_add = "Add is Required!";
      } else if (!values?.left_sph) {
        errors.left_sph = "SPH is Required!";
      } else if (!values?.left_cyl) {
        errors.left_cyl = "CYL is Required!";
      } else if (!values?.left_axis) {
        errors.left_axis = "Axis is Required!";
      } else if (
        !values?.left_add &&
        (selectedCategory?.category === "progressive" ||
          selectedCategory?.category === "bifocal")
      ) {
        errors.left_add = "Add is Required!";
      }
      return errors;
    },
  });

  // ---------------------- SELECT PRESCRIPTION FROM MY ACCOUNT --------------------------
  console.log("selectedMyPrescription", selectedMyPrescription);
  const selectMyPrescriptionHandle = () => {
    setAccountPrescriptionToggle(false);

    // setSelectedType(selectedCategory?.types[0]);
    prescriptionFormik.setFieldValue(
      "brandName",
      selectedMyPrescription?.brandName || ""
    );
    prescriptionFormik.setFieldValue(
      "lensType",
      selectedMyPrescription?.lensType || ""
    );
    prescriptionFormik.setFieldValue(
      "additionalInfo",
      selectedMyPrescription?.additionalInfo || ""
    );
    prescriptionFormik.setFieldValue(
      "right_sph",
      selectedMyPrescription?.rightEye?.sphere || ""
    );
    prescriptionFormik.setFieldValue(
      "right_cyl",
      selectedMyPrescription?.rightEye?.cylinder || ""
    );
    prescriptionFormik.setFieldValue(
      "right_axis",
      selectedMyPrescription?.rightEye?.axis || ""
    );
    prescriptionFormik.setFieldValue(
      "right_pd",
      selectedMyPrescription?.rightEye?.pd || ""
    );
    prescriptionFormik.setFieldValue(
      "right_add",
      selectedMyPrescription?.rightEye?.add || ""
    );
    prescriptionFormik.setFieldValue(
      "left_sph",
      selectedMyPrescription?.leftEye?.sphere || ""
    );
    prescriptionFormik.setFieldValue(
      "left_cyl",
      selectedMyPrescription?.leftEye?.cylinder || ""
    );
    prescriptionFormik.setFieldValue(
      "left_axis",
      selectedMyPrescription?.leftEye?.axis || ""
    );
    prescriptionFormik.setFieldValue(
      "left_pd",
      selectedMyPrescription?.leftEye?.pd || ""
    );
    prescriptionFormik.setFieldValue(
      "left_add",
      selectedMyPrescription?.leftEye?.add || ""
    );
  };

  // ---------------------- DELETE PRESCRIPTION FROM MY ACCOUNT --------------------------

  const [deletePrescription] = useDeletePrescriptionMutation();

  const deletePrescriptionHandle = () => {
    deletePrescription({
      user: localStorage.getItem("user"),
      id: deletePrescriptionId,
    }).then((res) => {
      if (res?.data?.message) {
        toast.success("Address deleted successfully");
        setDeletePrescriptionId("");
      }
      if (res.data.error) {
        toast.error(res.data.error);
      }
    });
  };

  const [categoryGroupedData, setCategoryGroupedData] = useState([]);
  // const [typeGroupedData, setTypeGroupedData] = useState([]);

  // useEffect(() => {
  //   // Initialize an empty object for grouping
  //   const categoryGrouped = {};
  //   // Iterate over the data array
  //   lensListData?.data?.forEach((item) => {
  //     const formattedCategory = item.category
  //       ?.replace(/([a-z])([A-Z])/g, "$1 $2")
  //       ?.toLowerCase();
  //     // If the category does not exist in the object, initialize it as an array
  //     if (!categoryGrouped[formattedCategory]) {
  //       categoryGrouped[formattedCategory] = [];
  //     }
  //     // Add the item to the appropriate category array
  //     categoryGrouped[formattedCategory]?.push(item);
  //   });
  //   // Convert the grouped object into an array of objects
  //   const categoryGroupedArray = Object.keys(categoryGrouped)?.map(
  //     (category) => ({
  //       category,
  //       items: categoryGrouped[category],
  //     })
  //   );

  //   const transformedData = categoryGroupedArray.map(({ category, items }) => {
  //     // Group items by type within each category
  //     const types = items?.reduce((acc, item) => {
  //       const existingType = acc?.find((t) => t?.type === item?.type);
  //       if (existingType) {
  //         existingType?.items?.push(item);
  //       } else {
  //         acc.push({ type: item?.type, items: [item] });
  //       }
  //       return acc;
  //     }, []);

  //     return {
  //       category,
  //       items,
  //       types,
  //     };
  //   });

  //   setCategoryGroupedData(
  //     transformedData?.sort((a, b) => a?.items?.length - b?.items?.length)
  //   );
  //   // setSelectedCategory(transformedData[0]);
  // }, [lensListData?.data]);

  useEffect(() => {
    // // Group data by category
    // const groupedData = lensListData?.data?.reduce((acc, item) => {
    //   const category = item.category;
    //   if (!acc[category]) {
    //     acc[category] = [];
    //   }
    //   acc[category].push(item);
    //   return acc;
    // }, {});

    // // Sort each category group by sortOrder
    // if (groupedData) {
    //   Object.keys(groupedData).forEach((category) => {
    //     groupedData[category].sort((a, b) => {
    //       if (a.sortOrder === "" && b.sortOrder === "") {
    //         return 0; // If both have no sortOrder, keep the original order.
    //       }
    //       if (a.sortOrder === "") {
    //         return 1; // Move items with no sortOrder to the end.
    //       }
    //       if (b.sortOrder === "") {
    //         return -1; // Keep items with valid sortOrder before those with no sortOrder.
    //       }
    //       return parseInt(a.sortOrder) - parseInt(b.sortOrder); // Sort by numerical sortOrder.
    //     });
    //   });
    // }

    const categoryOrder = ["singlevision", "bifocal", "progressive", "suntint"];

    // Group products by category
    const grouped = lensListData?.data?.reduce((acc, product) => {
      const category = product.category || "Uncategorized"; // Fallback for uncategorized items
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});

    // Sort the products within each category by sortOrder
    if (grouped) {
      Object.keys(grouped).forEach((category) => {
        grouped[category].sort(
          (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
        );
      });
    }

    // Convert the object back into an array and sort categories by predefined order
    if (grouped) {
      const resultArray = Object.entries(grouped)
        .map(([category, items]) => ({
          category,
          items,
        }))
        .sort((a, b) => {
          const aIndex = categoryOrder.indexOf(a.category);
          const bIndex = categoryOrder.indexOf(b.category);
          return (
            (aIndex === -1 ? Infinity : aIndex) -
            (bIndex === -1 ? Infinity : bIndex)
          );
        });

      setCategoryGroupedData(resultArray);
    }
  }, [lensListData?.data]);

  // ====================== LENS ADD TO BAG HANDLE ================================
  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = (productId, prescriptionId, lensId, addonsArray) => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: productId,
              quantity: location?.state?.isOnlyLens === true ? 0 : 1,
              variant: [],
              lens: [
                {
                  lens_id: lensId,
                  prescription_id: prescriptionId,
                  addons: selectedAddons?.map((item) => item?.addon?.addon_id),
                },
              ],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Lens added to bag successfully.`);
          setStep(1);
          navigate("/shopping-bag");
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    }
  };

  const selectLensHandle = () => {
    addToMyBagHandel(
      location?.state?.id,
      selectedMyPrescription?._id,
      selectedLens?._id,
      selectedAddons
    );
  };

  // ==================== SELECT ADDONS HANDLE ===================

  const [selectedAddons, setSelectedAddons] = useState([]);

  const selectAddonsHandle = (addon) => {
    let isNotSame = selectedAddons?.some((item) => item?._id !== addon?._id);

    if (isNotSame && selectedAddons?.length > 0) {
      setSelectedAddons([]);
      setSelectedAddons([addon]);
    } else {
      const idx = selectedAddons?.findIndex(
        (i) => i?.addon?.addon_id === addon?.addon?.addon_id
      );
      if (idx === -1) {
        let clone = [...selectedAddons];
        clone?.push(addon);
        setSelectedAddons(clone);
      } else {
        let clone = [...selectedAddons];
        clone?.splice(idx, 1);
        setSelectedAddons(clone);
      }
    }
  };
  const calculateTotalPrice = () => {
    return selectedAddons?.reduce(
      (total, addon) => total + addon?.addon?.price,
      0
    );
  };
  return (
    <div className="Lens pt-10 pb-10 bg-gray hide_scrollbar h-full">
      {/* ------------------------- steps lg ----------------------------------*/}
      <div className="flex items-center justify-center">
        <div className="lg:flex emd:hidden small:hidden steps justify-center text-lg">
          <div className="flex justify-center items-center">
            <div
              onClick={() => {
                setStep(1);
                setSelectedPrescriptionUploadType("");
              }}
              className="cursor-pointer min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full flex justify-center items-center border border-primary text-primary font-bold"
            >
              1
            </div>
            <div
              onClick={() => {
                setStep(1);
                setSelectedPrescriptionUploadType("");
              }}
              className="cursor-pointer ms-3 me-1"
            >
              Lens Type
            </div>
            <div className="text-light_secondary text-nowrap">---------</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              onClick={() => {
                setStep(2);
                setPrescriptionUploadType("manual");
                setSelectedPrescriptionUploadType("manual");
              }}
              className="cursor-pointer min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full flex justify-center items-center border border-primary text-primary font-bold"
            >
              2
            </div>
            <div
              onClick={() => {
                setStep(2);
                setPrescriptionUploadType("manual");
                setSelectedPrescriptionUploadType("manual");
              }}
              className="cursor-pointer ms-3 me-1"
            >
              Prescription
            </div>
            <div className="text-light_secondary text-nowrap">---------</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              onClick={() => {
                if (selectedMyPrescription === null) {
                  return;
                }
                setStep(3);
                // setSelectedType(selectedCategory?.types[0]);
                setSelectedPrescriptionUploadType("");
              }}
              className="cursor-pointer min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full flex justify-center items-center border border-primary text-primary font-bold"
            >
              3
            </div>
            <div
              onClick={() => {
                if (selectedMyPrescription === null) {
                  return;
                }
                setStep(3);
                // setSelectedType(selectedCategory?.types[0]);
                setSelectedPrescriptionUploadType("");
              }}
              className="cursor-pointer ms-3 me-1"
            >
              Lens
            </div>
          </div>
        </div>
        {/* ----------------------------- steps MOBILE AND TABLET ----------------------------  */}
        <div className="lg:hidden emd:flex small:flex steps justify-center text-lg">
          <div className="flex justify-center items-start">
            <div className="flex flex-col items-center">
              <div
                onClick={() => {
                  setStep(1);
                  setSelectedPrescriptionUploadType("");
                }}
                className="cursor-pointer small:min-w-[20px] small:max-w-[20px] emd:min-w-[25px] emd:max-w-[25px] small:min-h-[20px] small:max-h-[20px] emd:min-h-[25px] emd:max-h-[25px] small:text-[10px] emd:text-base rounded-full flex justify-center items-center border border-primary text-primary font-bold"
              >
                1
              </div>
              <div
                onClick={() => {
                  setStep(1);
                  setSelectedPrescriptionUploadType("");
                }}
                className="cursor-pointer small:text-[10px] emd:text-base text-center leading-normal mt-2"
              >
                Lens Type
              </div>
            </div>
            <div className="text-light_secondary text-nowrap">---------</div>
          </div>
          <div className="flex justify-center items-start">
            <div className="flex flex-col items-center">
              <div
                onClick={() => {
                  setStep(2);
                  setPrescriptionUploadType("manual");
                  setSelectedPrescriptionUploadType("manual");
                }}
                className="cursor-pointer small:min-w-[20px] small:max-w-[20px] emd:min-w-[25px] emd:max-w-[25px] small:min-h-[20px] small:max-h-[20px] emd:min-h-[25px] emd:max-h-[25px] small:text-[10px] emd:text-base rounded-full flex justify-center items-center border border-primary text-primary font-bold"
              >
                2
              </div>
              <div
                onClick={() => {
                  setStep(2);
                  setPrescriptionUploadType("manual");
                  setSelectedPrescriptionUploadType("manual");
                }}
                className="cursor-pointer small:text-[10px] emd:text-base text-center leading-normal mt-2"
              >
                Prescription
              </div>
            </div>
            <div className="text-light_secondary text-nowrap">---------</div>
          </div>
          <div className="flex justify-center items-start">
            <div className="flex flex-col items-center">
              <div
                onClick={() => {
                  if (selectedMyPrescription === null) {
                    return;
                  }
                  setStep(3);
                  // setSelectedType(selectedCategory?.types[0]);
                  setSelectedPrescriptionUploadType("");
                }}
                className="cursor-pointer small:min-w-[20px] small:max-w-[20px] emd:min-w-[25px] emd:max-w-[25px] small:min-h-[20px] small:max-h-[20px] emd:min-h-[25px] emd:max-h-[25px] small:text-[10px] emd:text-base rounded-full flex justify-center items-center border border-primary text-primary font-bold"
              >
                3
              </div>
              <div
                onClick={() => {
                  if (selectedMyPrescription === null) {
                    return;
                  }
                  setStep(3);
                  // setSelectedType(selectedCategory?.types[0]);
                  setSelectedPrescriptionUploadType("");
                }}
                className="cursor-pointer small:text-[10px] emd:text-base text-center leading-normal mt-2"
              >
                Lens
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*---------------------------- Upload prescription ------------------------------*/}

      {/* 1st step cards selection */}
      {step === 1 && (
        <div className="h-[100vh]">
          <div className="flex flex-wrap justify-center gap-6 pt-16 pb-16 lg:h-[65dvh] emd:h-[70dvh] small:h-[70dvh] hide_scrollbar overflow-auto">
            {categoryGroupedData?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedCategory?.category === item?.category
                      ? "border-2 border-primary"
                      : "border-2 border-transparent"
                  } min-w-[270px] max-w-[270px] h-fit shadow-3xl p-5 ps-7 pe-7 pb-14 cursor-pointer`}
                  onClick={() => {
                    setSelectedCategory(item);
                    setStep(2);
                    setPrescriptionUploadType("manual");
                    setSelectedPrescriptionUploadType("manual");
                  }}
                >
                  <div className="relative flex justify-center items-center">
                    <img
                      className="min-h-[150px] max-h-[150px]"
                      src={item?.items[0]?.image}
                      alt="Frame"
                    />
                  </div>
                  <div className="text-center text-lg mt-2 mb-2 capitalize">
                    {item?.category}
                  </div>
                  <div className="text-center text-xs mont-regular">
                    Everyday use for those who are nearsighted (-) and
                    farsighted(+)
                  </div>
                </div>
              );
            })}
          </div>
          {/* last confirm */}
          {/* <div className="flex justify-between pt-4 pb-4 ps-16 pe-16 shadow-3xl-top">
            <div className="ms-14 text-2xl"></div>
            <BTN
              title={"CONFIRM"}
              className="me-14 rounded-none w-fit py-3 px-16 text-base"
              onP={() =>  setStep(2)}
            />
          </div> */}
        </div>
      )}

      {/* 2nd step cards selection */}
      {step === 2 && (
        <div className="h-[100vh]">
          {selectedPrescriptionUploadType === "" ? (
            <div>
              <div className="flex flex-wrap justify-center gap-6 mt-16 mb-16">
                {/* <div
                  className={` ${
                    prescriptionUploadType === "manual"
                      ? "border-2 border-primary"
                      : "border-2 border-transparent"
                  } min-w-[270px] max-w-[270px]  shadow-3xl p-5 ps-7 pe-7 pb-14 cursor-pointer`}
                  onClick={() => {
                    setPrescriptionUploadType("manual");
                    setSelectedPrescriptionUploadType(prescriptionUploadType);
                  }}
                >
                  <div className=" min-h-[150px] max-h-[150px] relative flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48px"
                      viewBox="0 -960 960 960"
                      width="48px"
                      fill="#5f6368"
                    >
                      <path d="M80 0v-61.77h800V0H80Zm150.77-277.92h37.08l388.69-388.7-18.16-18.92-18.92-18.15-388.69 387.92v37.85ZM200-247.15v-81.08l470.15-470.16q5.16-5.15 11.16-7.23 6-2.07 11.94-2.07 5.16 0 10.96 2.07 5.79 2.08 10.71 7.23l36.31 35.54q4.39 4.93 6.96 10.78 2.58 5.86 2.58 11.87 0 5.74-2.69 11.86-2.7 6.12-6.85 11.03L280.31-247.15H200Zm528.31-493.16-36.39-36.38 36.39 36.38Zm-71.77 73.69-18.16-18.92-18.92-18.15 37.08 37.07Z" />
                    </svg>
                  </div>
                  <div className="text-center text-lg mt-2 mb-2">
                    Enter manually
                  </div>
                  <div className="text-center text-xs mont-regular">
                    I would like to enter my details now
                  </div>
                </div> */}
                {/* <div
                  className={` ${
                    prescriptionUploadType === "upload"
                      ? "border-2 border-primary"
                      : "border-2 border-transparent"
                  } min-w-[270px] max-w-[270px]  shadow-3xl p-5 ps-7 pe-7 pb-14 cursor-pointer`}
                  onClick={() => setPrescriptionUploadType("upload")}
                >
                  <div className=" min-h-[150px] max-h-[150px] relative flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48px"
                      viewBox="0 -960 960 960"
                      width="48px"
                      fill="#5f6368"
                    >
                      <path d="M464.62-333.77v-366.38l-100.77 100-22.23-21.47L480-760l138.38 138.38-22.23 21.47-100.77-100v366.38h-30.76ZM255.38-200q-23.05 0-39.22-16.16Q200-232.33 200-255.38V-363h30.77v107.62q0 9.23 7.69 16.92 7.69 7.69 16.92 7.69h449.24q9.23 0 16.92-7.69 7.69-7.69 7.69-16.92V-363H760v107.62q0 23.05-16.16 39.22Q727.67-200 704.62-200H255.38Z" />
                    </svg>
                  </div>
                  <div className="text-center text-lg mt-2 mb-2">
                    Upload Prescription
                  </div>
                  <div className="text-center text-xs mont-regular">
                    I would like to upload a picture of my prescription
                  </div>
                </div> */}
                {/* <div
                  className={` ${
                    prescriptionUploadType === "account"
                      ? "border-2 border-primary"
                      : "border-2 border-transparent"
                  } min-w-[270px] max-w-[270px]  shadow-3xl p-5 ps-7 pe-7 pb-14 cursor-pointer`}
                  onClick={() => {
                    setPrescriptionUploadType("account");
                    setAccountPrescriptionToggle(true);
                  }}
                >
                  <div className=" min-h-[150px] max-h-[150px] relative flex justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48px"
                      viewBox="0 -960 960 960"
                      width="48px"
                      fill="#5f6368"
                    >
                      <path d="M247.85-260.62q51-36.69 108.23-58.03Q413.31-340 480-340t123.92 21.35q57.23 21.34 108.23 58.03 39.62-41 63.73-96.84Q800-413.31 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 66.69 24.12 122.54 24.11 55.84 63.73 96.84ZM480.02-460q-50.56 0-85.29-34.71Q360-529.42 360-579.98q0-50.56 34.71-85.29Q429.42-700 479.98-700q50.56 0 85.29 34.71Q600-630.58 600-580.02q0 50.56-34.71 85.29Q530.58-460 480.02-460ZM480-120q-75.31 0-141-28.04t-114.31-76.65Q176.08-273.31 148.04-339 120-404.69 120-480t28.04-141q28.04-65.69 76.65-114.31 48.62-48.61 114.31-76.65Q404.69-840 480-840t141 28.04q65.69 28.04 114.31 76.65 48.61 48.62 76.65 114.31Q840-555.31 840-480t-28.04 141q-28.04 65.69-76.65 114.31-48.62 48.61-114.31 76.65Q555.31-120 480-120Zm0-40q55.31 0 108.85-19.35 53.53-19.34 92.53-52.96-39-31.31-90.23-49.5Q539.92-300 480-300q-59.92 0-111.54 17.81-51.61 17.81-89.84 49.88 39 33.62 92.53 52.96Q424.69-160 480-160Zm0-340q33.69 0 56.85-23.15Q560-546.31 560-580t-23.15-56.85Q513.69-660 480-660t-56.85 23.15Q400-613.69 400-580t23.15 56.85Q446.31-500 480-500Zm0-80Zm0 350Z" />
                    </svg>
                  </div>
                  <div className="text-center text-lg mt-2 mb-2">
                    My Account
                  </div>
                  <div className="text-center text-xs mont-regular">
                    Choose my prescription from my account
                  </div>
                </div> */}
              </div>
              {/* last confirm */}
              {/* <div className="flex justify-between pt-4 pb-4 ps-16 pe-16 shadow-3xl-top">
                <div className="ms-14 text-2xl"></div>
                <BTN
                  title={"CONFIRM"}
                  className="me-14 rounded-none w-fit py-3 px-16 text-base"
                  onP={() => confirmPrescriptionSelectTypeHandle()}
                />
              </div> */}
            </div>
          ) : selectedPrescriptionUploadType === "upload" ? (
            <div>
              <div className="w-full flex lg:flex-row emd:flex-col small:flex-col justify-center items-center flex-wrap lg:ps-40 lg:pe-40 mt-16 mb-16">
                <div className="lg:w-7/12 emd:w-1/2 small:w-full flex items-center justify-center p-4">
                  <div className="h-[300px] lg:w-1/2 emd:w-full small:w-full flex flex-col items-center justify-center border border-primary shadow-lg">
                    <div className="material-symbols-outlined">
                      <img src={UPLOAD_IC} alt="" className="w-[50px]" />
                    </div>
                    <span className="mt-2 text-lg">Drop file to Upload </span>
                    <div className="px-4 py-2 mt-5 text-nowrap cursor-pointer text-primary border border-primary">
                      SELECT FILE
                    </div>
                  </div>
                </div>
                <div className="lg:w-5/12 emd:w-1/2 small:w-full p-4 ">
                  <div className="text-2xl">Upload Prescription</div>
                  <div className="flex w-full mt-5">
                    <div className="relative">
                      <img
                        src={CIRCLE_CLOSE_IC}
                        alt=""
                        className="absolute cursor-pointer -right-1 -top-1"
                      />
                      <img src={PDF_IC} alt="" className="w-14" />
                    </div>
                    <div className="relative">
                      <img
                        src={CIRCLE_CLOSE_IC}
                        alt=""
                        className="absolute cursor-pointer -right-1 -top-1"
                      />
                      <img src={PDF_IC} alt="" className="w-14" />
                    </div>
                  </div>
                  <div className="text-xl mt-10">Additional Information</div>
                  <INPUT
                    placeHolder={
                      "Any further  instructions regarding eye conditions or lens requirements."
                    }
                    mainClass={"mt-4 "}
                  />
                </div>
              </div>
              {/* last confirm */}
              <div className="flex justify-between pt-4 pb-4 ps-16 pe-16 shadow-3xl-top">
                <div className="ms-14 text-2xl">&#x20B9;0&nbsp;</div>
                <BTN
                  title={"CONFIRM"}
                  className="me-14 rounded-none w-fit py-3 px-16 text-base"
                  onP={() => confirmPrescriptionUploadTypeHandle()}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="mt-16 mb-16">
                <div className="text-center text-2xl mt-10 mb-10 mont-bold">
                  Enter Prescription Details
                </div>
                <div className="w-full flex items-end lg:ps-40 lg:pe-40 lg:text-xl emd:text-xl small:text-lg px-4">
                  <div className="lg:text-xl emd:text-xl small:text-[10px] w-fit mont-bold flex mb-4 lg:ps-4 emd:ps-0 small:ps-0">
                    NAME
                  </div>
                  <INPUT
                    mainClass={"ms-5"}
                    className={
                      "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1"
                    }
                    placeHolder={"Name"}
                    name="brandName"
                    val={prescriptionFormik?.values?.brandName}
                    onBlur={prescriptionFormik.handleBlur}
                    touched={prescriptionFormik.touched?.brandName}
                    onChange={(e) => {
                      prescriptionFormik.handleChange(e);
                    }}
                    errorMsg={prescriptionFormik.errors.brandName}
                    errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                  />
                </div>
                <div className="flex lg:flex-row emd:flex-col small:flex-col lg:ps-40 lg:pe-40 mt-10 mb-10">
                  <div
                    className={` ${
                      selectedCategory?.category === "progressive" ||
                      selectedCategory?.category === "bifocal"
                        ? "grid-cols-6"
                        : "grid-cols-5"
                    } lg:w-full px-4 gap-y-2 lg:gap-x-6 emd:gap-x-4 small:gap-x-2 emd:w-full small:w-full grid`}
                  >
                    <div className="lg:text-xl emd:text-xl small:text-sm mont-bold flex"></div>
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex mb-4 ps-4">
                      SPH
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex mb-4 ps-4">
                      CYL
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex mb-4 ps-4">
                      AXIS
                    </div>
                    {(selectedCategory?.category === "progressive" ||
                      selectedCategory?.category === "bifocal") && (
                      <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex mb-4 ps-4">
                        ADD
                      </div>
                    )}
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex mb-4 ps-4">
                      PD
                      {/* <div className="text-center select-none ms-1 text-[10px] leading-normal underline text-primary cursor-pointer">
                        I have 2 PD values
                      </div> */}
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex items-end pb-6">
                      OD (Right Eye)
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"SPH (+/- 1)"}
                        name="right_sph"
                        onChange={(e) => {
                          if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          } else {
                            toast.error("SPH contain only ( +/- ) values!", {
                              autoClose: 2000,
                            });
                          }
                        }}
                        val={prescriptionFormik.values?.right_sph}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik?.touched?.right_sph}
                        errorMsg={prescriptionFormik.errors.right_sph}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"CYL (+/- 1)"}
                        name="right_cyl"
                        onChange={(e) => {
                          if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          } else {
                            toast.error("CYL contain only ( +/- ) values!", {
                              autoClose: 2000,
                            });
                          }
                        }}
                        val={prescriptionFormik?.values?.right_cyl}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik?.touched?.right_cyl}
                        errorMsg={prescriptionFormik.errors.right_cyl}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"AXIS"}
                        name="right_axis"
                        val={prescriptionFormik?.values?.right_axis}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik?.touched?.right_axis}
                        onChange={(e) => {
                          if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          }
                        }}
                        errorMsg={prescriptionFormik.errors.right_axis}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    {(selectedCategory?.category === "progressive" ||
                      selectedCategory?.category === "bifocal") && (
                      <div className="lg:text-xl emd:text-xl small:text-lg ">
                        <INPUT
                          className={
                            "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                          }
                          placeHolder={"ADD"}
                          name="right_add"
                          maxLength={5}
                          val={prescriptionFormik?.values?.right_add}
                          onBlur={prescriptionFormik.handleBlur}
                          touched={prescriptionFormik.touched?.right_add}
                          onChange={(e) => {
                            if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                              prescriptionFormik.handleChange(e);
                            } else if (e.target.value === "") {
                              prescriptionFormik.handleChange(e);
                            }
                          }}
                          errorMsg={prescriptionFormik.errors.right_add}
                          errorTextClass={
                            "lg:text-sm emd:text-xs small:text-xs"
                          }
                        />
                      </div>
                    )}
                    <div className="lg:text-xl emd:text-xl small:text-lg ">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"PD"}
                        name="right_pd"
                        val={prescriptionFormik?.values?.right_pd}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik.touched?.right_pd}
                        onChange={(e) => {
                          if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          }
                        }}
                        errorMsg={prescriptionFormik.errors.right_pd}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-[10px] mont-bold flex items-end pb-6">
                      OS (Left Eye)
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"SPH (+/- 1)"}
                        name="left_sph"
                        onChange={(e) => {
                          if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          } else {
                            toast.error("SPH contain only ( +/- ) values!", {
                              autoClose: 2000,
                            });
                          }
                        }}
                        val={prescriptionFormik.values?.left_sph}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik.touched?.left_sph}
                        errorMsg={prescriptionFormik.errors.left_sph}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"CYL (+/- 1)"}
                        name="left_cyl"
                        onChange={(e) => {
                          if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          } else {
                            toast.error("CYL contain only ( +/- ) values!", {
                              autoClose: 2000,
                            });
                          }
                        }}
                        val={prescriptionFormik.values?.left_cyl}
                        onBlur={prescriptionFormik.handleBlur}
                        errorMsg={prescriptionFormik.errors.left_cyl}
                        touched={prescriptionFormik.touched?.left_cyl}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>
                    <div className="lg:text-xl emd:text-xl small:text-lg">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"AXIS"}
                        name="left_axis"
                        val={prescriptionFormik.values?.left_axis}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik.touched?.left_axis}
                        onChange={(e) => {
                          if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          }
                        }}
                        errorMsg={prescriptionFormik.errors.left_axis}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>

                    {(selectedCategory?.category === "progressive" ||
                      selectedCategory?.category === "bifocal") && (
                      <div className="lg:text-xl emd:text-xl small:text-lg ">
                        <INPUT
                          className={
                            "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                          }
                          maxLength={5}
                          placeHolder={"ADD"}
                          name="left_add"
                          val={prescriptionFormik.values.left_add}
                          onBlur={prescriptionFormik.handleBlur}
                          touched={prescriptionFormik.touched?.left_add}
                          onChange={(e) => {
                            if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                              prescriptionFormik.handleChange(e);
                            } else if (e.target.value === "") {
                              prescriptionFormik.handleChange(e);
                            }
                          }}
                          errorMsg={prescriptionFormik.errors.left_add}
                          errorTextClass={
                            "lg:text-sm emd:text-xs small:text-xs"
                          }
                        />
                      </div>
                    )}

                    <div className="lg:text-xl emd:text-xl small:text-lg ">
                      <INPUT
                        className={
                          "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                        }
                        maxLength={5}
                        placeHolder={"PD"}
                        name="left_pd"
                        val={prescriptionFormik.values.left_pd}
                        onBlur={prescriptionFormik.handleBlur}
                        touched={prescriptionFormik.touched?.left_pd}
                        onChange={(e) => {
                          if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                            prescriptionFormik.handleChange(e);
                          } else if (e.target.value === "") {
                            prescriptionFormik.handleChange(e);
                          }
                        }}
                        errorMsg={prescriptionFormik.errors.left_pd}
                        errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
                      />
                    </div>

                    {/* <div className="text-sm  flex justify-end flex-col">
                      <div className="mont-bold">I dont know PD</div>
                      <div className="mt-1 text-xs">&nbsp;</div>
                    </div> */}
                  </div>

                  {/* <div className="lg:w-[30%] emd:w-full small:w-full p-4">
                    <div className="text-xl">Additional Information</div>
                    <INPUT
                      className={
                        "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                      }
                      placeHolder={
                        "Any further  instructions regarding eye conditions or lens requirements."
                      }
                      mainClass={"mt-4 "}
                      name="additionalInfo"
                      onChange={prescriptionFormik.handleChange}
                      val={prescriptionFormik.values.additionalInfo}
                      onBlur={prescriptionFormik.handleBlur}
                      // errorMsg={prescriptionFormik.errors.additionalInfo}
                      touched={prescriptionFormik.touched.additionalInfo}
                    />
                  </div> */}
                </div>
              </div>
              {/* last confirm */}
              <div className="flex justify-end items-center py-4 px-4 shadow-3xl-top">
                <div className="me-10 text-base">
                  Choose my prescription from{" "}
                  <span
                    onClick={() => setAccountPrescriptionToggle(true)}
                    className="text-nowrap cursor-pointer underline text-lg text-primary"
                  >
                    My Account
                  </span>
                </div>
                <BTN
                  title={"CONFIRM"}
                  type={"submit"}
                  className="lg:me-28 emd:me-14 small:me-0 rounded-none w-fit lg:py-3 lg:px-16 lg:text-base emd:py-3 emd:px-16 emd:text-base small:py-2 small:px-8 small:text-xs"
                  onP={prescriptionFormik.handleSubmit}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* 3rd step cards selection */}

      {step === 3 && (
        <div className="relative h-[100vh] overflow-auto hide_scrollbar">
          <div className="w-full hide_scrollbar flex flex-col items-center justify-center lg:px-0 emd:px-5 small:px-3">
            {/* <div className="lg:w-[30%] flex items-center justify-center mt-10 mb-6 border-b border-secondary">
              {selectedCategory?.types?.map((item, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => setSelectedType(item)}
                    className={`${
                      selectedType?.type === item?.type
                        ? "border-b-2 border-primary"
                        : "border-b-2 border-transparent"
                    } ${
                      index !== 0 ? "ms-5" : ""
                    } pb-2 text-2xl font-bold cursor-pointer select-none capitalize`}
                  >
                    {item?.type !== "none" ? item?.type : ""}
                  </span>
                );
              })}
            </div> */}

            <div className="lg:w-[80%] emd:w-full small:w-full pb-20 mt-10 hide_scrollbar overflow-auto lg:px-0 emd:px-5 small:px-3">
              <div className="grid lg:grid-cols-2 emd:grid-cols-1 small:grid-cols-1 gap-4">
                {selectedCategory?.items?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedLens(item);
                        if (item?._id !== selectedLens?._id) {
                          setSelectedAddons([]);
                        }
                      }}
                      key={index}
                      className={` ${
                        selectedLens?._id === item?._id
                          ? "border-2 border-primary"
                          : "border-2 border-transparent"
                      } flex lg:flex-row emd:flex-col small:flex-col justify-between w-full bg-white shadow-lg rounded-lg p-4 cursor-pointer`}
                    >
                      <div className="lg:w-[30%] emd:w-full small:w-full flex flex-col items-center justify-center">
                        <img
                          src={item?.image}
                          alt={""}
                          className="lg:max-w-full emd:max-w-[150px] small:max-w-[150px] object-contain"
                        />
                      </div>
                      <div className="lg:w-[65%] emd:w-full small:w-full lg:mt-0 emd:mt-5 small:mt-5 flex flex-col items-start">
                        <h2 className="text-xl font-semibold mb-2 text-left capitalize">
                          {item?.name} ({item?.lensCode})
                        </h2>
                        <ul className="text-gray-600 mb-4 grid xl:grid-cols-2 fix_lg:grid-cols-1 mxs:grid-cols-1 emd:grid-cols-2 small:grid-cols-1 gap-2">
                          {JSON.parse(item?.descriptionArray[0])?.map(
                            (feature, index) => (
                              <li
                                key={index}
                                className="list-disc list-inside text-nowrap"
                              >
                                {feature}
                              </li>
                            )
                          )}
                        </ul>

                        <div className="border-t border-primary w-full h-1 my-4"></div>
                        {item?.addons?.length > 0 && (
                          <div className="mb-4">
                            <span className="block font-semibold mb-2">
                              {selectedCategory?.category === "suntint"
                                ? "Customise"
                                : "Add on"}
                            </span>
                            <div className="grid xl:grid-cols-2 fix_lg:grid-cols-1 mxs:grid-cols-1 emd:grid-cols-2 small:grid-cols-1 gap-2">
                              {item?.addons.map((addon, index) => (
                                <div key={index} className="flex items-center">
                                  <input
                                    type="checkbox"
                                    id={
                                      addon.name + addon?.addon_id + item?._id
                                    }
                                    checked={selectedAddons?.some(
                                      (itm) =>
                                        addon?.addon_id ===
                                          itm?.addon?.addon_id &&
                                        itm?._id === item?._id
                                    )}
                                    className="mr-2 cursor-pointer accent-primary"
                                    onChange={() =>
                                      selectAddonsHandle({
                                        addon,
                                        _id: item?._id,
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor={
                                      addon.name + addon?.addon_id + item?._id
                                    }
                                    className="cursor-pointer"
                                  >
                                    {addon.name} (₹{addon.price})
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <div className="text-2xl font-bold text-primary text-center">
                          ₹{item?.price}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="total z-10 w-full fixed bottom-0 bg-white flex mt-5 items-center justify-between pt-4 pb-4 lg:ps-16 lg:pe-16 emd:ps-16 emd:pe-16 small:ps-5 small:pe-5 shadow-md">
            <div className="lg:ms-14 emd:ms-10 small:ms-0 text-2xl">
              Total ₹{calculateTotalPrice() + selectedLens?.price || 0}
            </div>
            <BTN
              title={"CONFIRM"}
              className="lg:me-14 emd:ms-10 small:ms-0 rounded-none w-fit py-3 lg:px-16 emd:px-12 small:px-10 text-base"
              onP={selectLensHandle}
            />
          </div>
        </div>
      )}

      {/* ============================== PRESCRIPTION LISTING FROM MY ACCOUNT ====================== */}
      <MODEL
        showModel={accountPrescriptionToggle}
        setShowModel={setAccountPrescriptionToggle}
        title="Select prescription"
      >
        {prescriptionListData?.lensPrescription?.length > 0 ? (
          <div className="w-full flex flex-col items-end ">
            <div className="w-full min-h-[60dh] max-h-[60dvh] overflow-auto lg:px-8 lg:pb-8 lg:pt-0 emd:px-8 emd:pb-8 emd:pt-0 small:px-5 small:pb-5 small:pt-0 grid lg:grid-cols-2 emd:grid-cols-1 small:grid-cols-1 auto-rows-auto hide_scrollbar items-center gap-x-4 gap-y-4">
              {prescriptionListData?.lensPrescription?.map((item, index) => {
                return (
                  <div
                    onClick={() => setSelectedMyMyPrescription(item)}
                    key={index}
                    className={` ${
                      item?._id === selectedMyPrescription?._id
                        ? "border-2 border-primary"
                        : "border-2 border-transparent"
                    } bg-gray overflow-hidden p-4 cursor-pointer select-none w-full flex flex-col`}
                  >
                    <div className="w-full flex items-center justify-between pb-2 border-b border-light_secondary">
                      <div>
                        <span className="text-base font-bold">{index + 1}</span>
                        {/* <span className="text-base font-bold">Brand :</span>
                    <span> {item?.brandName}</span> */}
                      </div>
                      <div>
                        <img
                          src={DELETE_IC}
                          alt=""
                          className="w-5 z-999"
                          onClick={() => {
                            setDeletePrescriptionId(item?._id);
                          }}
                        />
                        {/* <span className="text-base font-bold">Type :</span>
                    <span> {item?.lensType}</span> */}
                      </div>
                    </div>
                    <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
                      <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                        <div className="my-2 text-center font-bold underline underline-offset-4">
                          Left Eye
                        </div>
                        <div className="w-full flex items-center justify-between mt-3">
                          <span className="text-sm font-bold">Sphere :</span>
                          <span className="text-sm">
                            {item?.leftEye?.sphere}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Cylinder :</span>
                          <span className="text-sm">
                            {item?.leftEye?.cylinder}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Axis :</span>
                          <span className="text-sm">{item?.leftEye?.axis}</span>
                        </div>
                        {item?.leftEye?.pd && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">PD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.leftEye?.pd}
                              </span>
                              {/* <span className="text-sm ms-2">
                          {item?.leftEye?.left_pd}
                        </span> */}
                            </div>
                          </div>
                        )}
                        {(selectedCategory?.category === "progressive" ||
                          selectedCategory?.category === "bifocal") &&
                          item?.leftEye?.add && (
                            <div className="w-full flex items-center justify-between">
                              <span className="text-sm font-bold">ADD :</span>
                              <div className="flex items-center">
                                <span className="text-sm">
                                  {item?.leftEye?.add}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                        <div className="my-2 text-center font-bold underline underline-offset-4">
                          Right Eye
                        </div>
                        <div className="w-full flex items-center justify-between mt-3">
                          <span className="text-sm font-bold">Sphere :</span>
                          <span className="text-sm">
                            {item?.rightEye?.sphere}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Cylinder :</span>
                          <span className="text-sm">
                            {item?.rightEye?.cylinder}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Axis :</span>
                          <span className="text-sm">
                            {item?.rightEye?.axis}
                          </span>
                        </div>
                        {item?.rightEye?.pd && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">PD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.rightEye?.pd}
                              </span>
                              {/* <span className="text-sm ms-2">
                          {item?.leftEye?.right_pd}
                        </span> */}
                            </div>
                          </div>
                        )}
                        {(selectedCategory?.category === "progressive" ||
                          selectedCategory?.category === "bifocal") &&
                          item?.rightEye?.add && (
                            <div className="w-full flex items-center justify-between">
                              <span className="text-sm font-bold">ADD :</span>
                              <div className="flex items-center">
                                <span className="text-sm">
                                  {item?.rightEye?.add}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <BTN
              title={"CONFIRM"}
              onP={selectMyPrescriptionHandle}
              className={"w-40 py-1 px-2 mb-4 mr-8 mt-4"}
            />
          </div>
        ) : (
          <div className="px-5 pb-10 h-56 flex flex-col items-center justify-center text-lg w-full text-light_secondary text-center">
            No any Prescription In Your Account!
          </div>
        )}
      </MODEL>

      {/* ============================== DELETE PRESCRIPTION ====================== */}
      <MODEL
        showModel={deletePrescriptionId !== ""}
        setShowModel={() => setDeletePrescriptionId("")}
        title="Delete prescription"
        isDialogue={true}
      >
        <div className="w-full p-4 flex flex-col items-center border-t border-light_secondary">
          <span className="text-lg text-center my-2">
            Are you sure, You want to delete this Prescription ?
          </span>
          <div className="flex items-center justify-evenly mt-6 w-full">
            <BTN
              title="No"
              onP={() => setDeletePrescriptionId("")}
              bordered
              className={"w-28 py-1 px-2"}
            />
            <BTN
              onP={() => deletePrescriptionHandle()}
              title="Yes"
              className={"w-28 py-1 px-2"}
            />
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default Lens;

// validate: (values) => {
//   let errors = {};
//   if (!values?.right_sph) {
//     errors.right_sph = "Right SPH is Required!";
//   } else if (!values?.right_cyl) {
//     errors.right_cyl = "Right CYL is Required!";
//   } else if (!values?.right_axis) {
//     errors.right_axis = "Right Axis is Required!";
//   } else if (!values?.left_sph) {
//     errors.left_sph = "Left SPH is Required!";
//   } else if (!values?.left_cyl) {
//     errors.left_cyl = "Left CYL is Required!";
//   } else if (!values?.left_axis) {
//     errors.left_axis = "Left Axis is Required!";
//   } else if (!values?.right_pd) {
//     errors.right_pd = "Right PD is Required!";
//   } else if (!values?.left_pd) {
//     errors.left_pd = "Left PD is Required!";
//   }
//   return errors;
// },
