import React, { useState } from "react";

const Rating = ({ ratingValue, className, space, isDisabled, setRatingValue }) => {
  const [rating, setRating] = useState(Math.round(ratingValue.toFixed(2)));
  const [hover, setHover] = useState(0);

  return (
    <div className={`flex ${space ? space : "space-x-2"} `}>
      {[...Array(5)].map((_, index) => {
        const currentRating = index + 1;

        return isDisabled ? (
          <button
            key={currentRating}
            type="button"
            className={` ${className ? className : "text-lg"} ${
              currentRating <= (hover || rating || Math.round(ratingValue.toFixed(2)))
                ? "text-primary"
                : "text-[#E0E0E0]"
            }`}
          >
            ★
          </button>
        ) : (
          <button
            key={currentRating}
            type="button"
            className={` ${className ? className : "text-lg"} ${
              currentRating <= (hover || rating || Math.round(ratingValue.toFixed(2)))
                ? "text-primary"
                : "text-[#E0E0E0]"
            }`}
            onClick={() => {setRating(currentRating); setRatingValue(currentRating)}}
            onMouseEnter={() => setHover(currentRating)}
            onMouseLeave={() => setHover(0)}
          >
            ★
          </button>
        );
      })}
    </div>
  );
};

export default Rating;

// import React, { useState } from "react";

// const Rating = ({ ratingValue = 0 }) => {
//   const [rating, setRating] = useState(ratingValue);
//   const [hover, setHover] = useState(0);

//   const getStarType = (index) => {
//     const currentRating = hover || rating;

//     if (index < currentRating - 0.5) {
//       return "full"; // Full star
//     }
//     if (index < currentRating && currentRating % 1 !== 0) {
//       return "half"; // Half star
//     }
//     return "empty"; // Empty star
//   };

//   return (
//     <div className="flex space-x-2">
//       {[...Array(5)].map((_, index) => {
//         const starType = getStarType(index);
//         return (
//           <button
//             key={index}
//             type="button"
//             className="text-lg"
//             onClick={() => setRating(index + 1)}
//             onMouseEnter={() => setHover(index + 1)}
//             onMouseLeave={() => setHover(0)}
//           >
//             {starType === "full" && <span className="text-primary">★</span>}
//             {starType === "half" && (
//               <span className="relative flex items-center justify-center">
//                 <span className="absolute text-primary left-0 top-0 w-1/2 overflow-hidden">
//                   ★
//                 </span>
//                 <span className="text-[#E0E0E0]">★</span>
//               </span>
//             )}
//             {starType === "empty" && <span className="text-[#E0E0E0]">★</span>}
//           </button>
//         );
//       })}
//     </div>
//   );
// };

// export default Rating;
