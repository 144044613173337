import "./premiumwear.scss";

import React, { useEffect, useState } from "react";

import ProductGrid from "../../Layout/ProductGrid/ProductGrid";
import { ProductCard } from "../../Pages/Category/Category";
import { toast } from "react-toastify";
import {
  useGetProductsMutationMutation,
  useGetProductsQuery,
} from "../../../redux/slices/homeSlice";
import { useNavigate } from "react-router-dom";
import { fakeData } from "../../../json";
import Skeleton from "react-loading-skeleton";
import Slider from "./Slider";

export default function PremiumWear({ homeData, blueCutLensIdDataLabel }) {
  const [selectedTab, setSelectedTab] = useState("Bluerim");

  // const setActive = (tab) => {
  //   if (tab === 1) {
  //     document.getElementById("tab-1").classList.add("active");
  //     document.getElementById("tab-2").classList.remove("active");
  //     setProducts(ProductsNetre);
  //   } else {
  //     document.getElementById("tab-2").classList.add("active");
  //     document.getElementById("tab-1").classList.remove("active");
  //     setProducts(ProductsBlurim);
  //   }
  // };

  const [products, setProducts] = useState([]);
  const [getProductsMutation] = useGetProductsMutationMutation();
  useEffect(() => {
    getProductsMutation({
      body: {
        attributes: {
          Brand: selectedTab,
        },
      },
      page: 1,
      limit: 10,
      queryParams: ``,
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res?.data.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, [selectedTab]);

  return (
    <div className="Premium_Wear_Container">
      <div className="w-full flex items-center justify-between">
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
        <span className="lg:text-3xl emd:text-xl small:text-lg text-center text-nowrap">
          {homeData?.brand_filter_products?.title}
        </span>
        <hr className="lg:w-1/3 emd:w-1/4 small:w-[10%]" />
      </div>
      {/* <div className="Premium_Wear_Tab">
        <div
          className={` ${selectedTab === "Bluerim" ? "active" : ""} tab-2 text-light_secondary text-lg h-full w-1/2 px-[20px] flex justify-center items-center cursor-pointer `}
          onClick={() => {
            setSelectedTab("Bluerim");
          }}
          id="tab-2"
        >
          Bluerim
        </div>
        <div
          className={` ${selectedTab === "netre" ? "active" : ""} tab-1 text-light_secondary text-lg h-full w-1/2 px-[20px] flex justify-center items-center cursor-pointer`}
          onClick={() => {
            setSelectedTab("netre");
          }}
          id="tab-1"
        >
          Netre
        </div>
      </div> */}
      {products?.length > 0 ? (
        <Slider
          products={products}
          isRetailer={false}
          blueCutLensIdDataLabel={blueCutLensIdDataLabel}
          isNewArrival={false}
        />
      ) : (
        <div className="flex flex-col justify-center items-center lg:min-h-[22vw] emd:min-h-[35vw] small:min-h-[75vw] lg:max-h-[22vw] emd:max-h-[35vw] small:max-h-[75vw]">
        <div className="text-light_secondary text-center text-2xl ">No Products</div>
        </div>
      )}
      {/* <VirtualProducts
        category={selectedTab}
        blueCutLensIdDataLabel={blueCutLensIdDataLabel}
      /> */}
    </div>
  );
}

function VirtualProducts(props) {
  const [products, setProducts] = useState([]);
  const [getProductsMutation, { isSuccess }] = useGetProductsMutationMutation();

  useEffect(() => {
    getProductsMutation({
      body: {
        attributes: {
          Brand: props?.category,
        },
      },
      page: 1,
      limit: 10,
      queryParams: "",
    }).then((res) => {
      if (res.data) {
        if (res.data) {
          setProducts(res.data?.products);
        }
      } else {
        toast.error(`${res?.error?.data?.message}`);
      }
    });
  }, [props?.category]);

  return (
    <div>
      {!isSuccess ? (
        <div className="cards">
          {fakeData?.slice(0, 6)?.map((item, index) => {
            return <Skeleton key={index} className="card" />;
          })}
        </div>
      ) : !products?.length > 0 ? (
        <div className="no_product_found">No Products</div>
      ) : (
        <div className="cards">
          {/* {isLoading && <div>Loading..</div>} */}
          {products &&
            products
              ?.slice(0, 6)
              ?.map((item, index) => (
                <ProductCard
                  key={index}
                  product={item}
                  route={"/virtual-tryon"}
                  storeType={"Virtual"}
                  {...props}
                  blueCutLensIdDataLabel={props?.blueCutLensIdDataLabel}
                />
              ))}
        </div>
      )}
    </div>
  );
}
