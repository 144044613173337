import React from "react";

const LensOnly = () => {
  return (
    <div className="w-full h-[90vh] flex items-center justify-center flex-col">
      <span className="text-primary text-3xl">Coming Soon</span>
    </div>
  );
};

export default LensOnly;
